/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.Vue = require('vue');

import App from './App.vue';
import VueAxios from 'vue-axios';
import VueRouter from 'vue-router';
import axios from 'axios';
import { routes } from './routes';
import i18n from "./i18n";
import Layouts from "./mixins/layouts.mixin";
import { store } from './store/store';
import constPlugin from './consts/config';
// Boostrap plugins
import { CardPlugin } from 'bootstrap-vue'
import { DropdownPlugin } from 'bootstrap-vue'
import { FormSelectPlugin } from 'bootstrap-vue'
import { FormPlugin } from 'bootstrap-vue'
import { BreadcrumbPlugin } from 'bootstrap-vue'
import { ModalPlugin } from 'bootstrap-vue'
import { TablePlugin } from 'bootstrap-vue'
import { FormRadioPlugin } from 'bootstrap-vue'
import { FormInputPlugin } from 'bootstrap-vue'
import { FormGroupPlugin } from 'bootstrap-vue'
import { FormCheckboxPlugin } from 'bootstrap-vue'
import { PaginationPlugin } from 'bootstrap-vue'
import { AlertPlugin } from 'bootstrap-vue'
import { ButtonPlugin } from 'bootstrap-vue'
// Other libs
import vClickOutside from "v-click-outside";
import VueMask from "v-mask";
import DateTimePicker from 'vue-bootstrap-datetimepicker';

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// Router and Axios
Vue.use(VueRouter);
Vue.use(VueAxios, axios);
const router = new VueRouter({
    mode: 'history',
    routes: routes
});

// Boostrap
Vue.use(CardPlugin)
Vue.use(DropdownPlugin)
Vue.use(FormSelectPlugin)
Vue.use(FormPlugin)
Vue.use(BreadcrumbPlugin)
Vue.use(ModalPlugin)
Vue.use(TablePlugin)
Vue.use(FormRadioPlugin)
Vue.use(FormInputPlugin)
Vue.use(FormGroupPlugin)
Vue.use(FormCheckboxPlugin)
Vue.use(PaginationPlugin)
Vue.use(AlertPlugin)
Vue.use(ButtonPlugin)


import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// Vue
Vue.use(vClickOutside);
Vue.use(VueMask);
Vue.use(DateTimePicker);
Vue.use(constPlugin);

// Import date picker css
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

const app = new Vue({
    el: '#app',
    store,
    beforeCreate() { this.$store.commit('initialiseStore'); },
    router: router,
    mixins: [Layouts],
    i18n,
    render: h => h(App),
});