<script>
import Vertical from "./horizontal";
import PageHeader from "../components/page-header";

export default {
  components: { Vertical, PageHeader },
  data() {
    return {
      header: {
        title: "",
        items: [
          {
            text: this.$t("module.home"),
            href: "/",
          },
          {
            text: "",
            active: true,
          },
        ],
      },
    };
  },
  computed: {
    layoutType() {
      return this.$root.layout?.type;
    },
  },
  beforeCreate: function () {
    document.body.className = "";
    document.getElementsByTagName("html")[0].className = "";
  },
  methods: {},
  mounted() {},
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'">
      <PageHeader :title="header.title" :items="header.items" />
      <router-view v-model="header"></router-view>
    </Vertical>
  </div>
</template>