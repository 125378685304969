// export default Object.freeze({
//     DATEFORMAT: "DD/MM/YYYY HH:mm:ss"
// })

const Configs = {
    DATE_FORMAT: "DD.MM.YYYY HH:mm:ss",
    REQUEST_DATE_FORMAT: "YYYY-MM-DD HH:mm:ss",
    HUMAN_READABLE_DATE_FORMAT: "D. (dddd) MMMM",
    // Frontend layout buttons
    TRAVELER_LAYOUT_BUTTONS: [
        { type: "label", text: "USERNAME", route: "profile"},
        { type: "route", text: "notes", route: "notes" },
        { type: "route", text: "unpack", route: "logout" }
    ],
    TRAVELER_LAYOUT_BUTTONS_WITH_ARCHIVE: [
        { type: "label", text: "USERNAME", route: "profile" },
        { type: "route", text: "archive", route: "archive" },
        { type: "route", text: "unpack", route: "logout" }
    ]
};

Configs.install = function (Vue) {
    Vue.prototype.$getConfig = (key) => {
        return Configs[key];
    }
};

export default Configs;