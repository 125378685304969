<template>
  <router-view> </router-view>
</template>
<script>
export default {};
</script>
<style lang="sass">
.background-blue {
  background-color: #2e69c7;
  color: white;
}
.background-green {
  background-color: #468628;
  color: white;
}
.background-red {
  background-color: #ce3740;
  color: white;
}
.background-orange {
  background-color: #cf9e35;
  color: white;
}
.background-yellow {
  background-color: yellow;
  color: black;
}
.body-blue {
  background-color: #2e69c7;
}
.body-green {
  background-color: #468628;
}
.body-red {
  background-color: #ce3740;
}
.body-orange {
  background-color: #cf9e35;
}
</style>