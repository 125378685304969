<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import moment from "moment";
import { getStats } from "../../services/group.api";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  data() {
    return {
      upcomingTasks: [],
      itemPosition: 0,
      items: [],
      bodyColor: "orange",
      headerFooter: {
        actions: this.$getConfig("TRAVELER_LAYOUT_BUTTONS").concat([
          { type: "route", text: "back", route: "trips" },
        ]),
      },
    };
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    moment.locale(locale);
    let start = moment(new Date()).add(1, "days");
    let end = start.clone().add(6, "days");
    this.init(start.toDate(), end.toDate());
  },
  methods: {
    // Get group statistics
    async getStats() {
      let dateFrom = this.upcomingTasks[0].title;
      let dateTo = this.upcomingTasks[this.upcomingTasks.length - 1].title;
      let resp = await getStats({
        date_from: dateFrom,
        date_to: dateTo,
      }).catch((res) => {});
      if (resp && resp.length > 0) {
        for (let [i, item] of resp.entries()) {
          this.updateStatsDAtaByDate(item);
        }
      }
    },
    updateStatsDAtaByDate(data) {
      for (let [i, day] of this.upcomingTasks.entries()) {
        if (day.title === data.day) {
          day.number_of_full_groups = data.number_of_full_groups;
          day.number_of_not_full_groups = data.number_of_not_full_groups;
          break;
        }
      }
    },
    redirectToTripDate(date) {
      this.$router.push({
        name: "trip.date",
        params: { date: date },
      });
    },
    moveDay(direction) {
      this.createDates(this.upcomingTasks.at(0).title, "day", direction);
    },
    moveWeek(direction) {
      this.createDates(
        "next" === direction
          ? this.upcomingTasks.at(-1).title
          : this.upcomingTasks.at(0).title,
        "week",
        direction
      );
    },
    moveMonth(direction) {
      this.createDates(this.upcomingTasks.at(-1).title, "month", direction);
    },
    createDates(startString, type, direction) {
      let start = moment(startString, "YYYY-MM-DD");
      let dir = "next" === direction ? 1 : -1;
      switch (type) {
        case "day":
          start.add(1 * dir, "days");
          break;
        case "week":
          start.add(7 * dir, "days");
          break;
        case "month":
          start.add(1 * dir, "M").startOf("month");
          break;
      }
      let end = start.clone().add(6, "days");
      this.init(start.toDate(), end.toDate());
    },
    init(start, end) {
      var daysOfYear = [];
      for (var d = start; d <= end; d.setDate(d.getDate() + 1)) {
        let obj = {};
        obj.title = moment(d).format("YYYY-MM-DD");
        obj.date =
          moment(d).format("ddd ").toUpperCase() + moment(d).format("D. MMMM");
        obj.number_of_full_groups = 0;
        obj.number_of_not_full_groups = 0;
        obj.class = this.getClass();
        obj.cols = Math.floor(Math.random() * (6 - 3) + 3);
        daysOfYear.push(obj);
      }
      this.upcomingTasks = daysOfYear;
      this.getStats();
    },
    getClass() {
      this.itemPosition++;

      if (this.itemPosition == 1) {
        return "start";
      }
      if (this.itemPosition == 3) {
        this.itemPosition = 0;
        return "end";
      }
      if (this.itemPosition == 2) {
        return "center";
      }
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor">
    <div class="content-body">
      <div class="day-row p-0 m-0">
        <b-row
          v-for="task in upcomingTasks"
          :key="task.title"
          @click="redirectToTripDate(task.title)"
          :align-h="task.class"
        >
          <b-col :cols="task.cols" class="p-2">
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark"
                ><i class="bx bx-calendar"></i> {{ task.date }}</a
              >
            </h5>
            <p class="mb-0">
              <span title="Number of full groups">
                <i class="bx bxs-circle"></i>
                {{ task.number_of_full_groups }}
              </span>
              <span class="ms-2" title="Number of not full groups">
                <i class="bx bxs-doughnut-chart"></i>
                {{ task.number_of_not_full_groups }}
              </span>
            </p>
          </b-col>
        </b-row>
      </div>
      <div class="row calendar-actions">
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveDay('previous')"
          >
            <i class="mdi mdi-arrow-collapse-left mr-1"></i> DAY
          </a>
        </div>
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveDay('next')"
          >
            DAY <i class="mdi mdi-arrow-collapse-right mr-1"></i>
          </a>
        </div>
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveWeek('previous')"
          >
            <i class="mdi mdi-arrow-collapse-left mr-1"></i> WEEK
          </a>
        </div>
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveWeek('next')"
          >
            WEEK <i class="mdi mdi-arrow-collapse-right mr-1"></i>
          </a>
        </div>
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveMonth('previous')"
          >
            <i class="mdi mdi-arrow-collapse-left mr-1"> MONTH</i>
          </a>
        </div>
        <div class="col-lg-2 col-6 d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-lg btn-block mt-3"
            @click="moveMonth('next')"
          >
            MONTH <i class="mdi mdi-arrow-collapse-right mr-1"></i>
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.calendar-actions > div > .btn {
  font-size: 1.9rem !important;
}
.day-row > .row {
  margin-left: 0;
  margin-right: 0;
}
.day-row > .row > div {
  background-color: yellow;
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin-bottom: 10px;
}
.list-group-item {
  background-color: transparent;
  border: 0;
}
.content-body {
}
</style>