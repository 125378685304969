import moment from "moment";

export default {
    data() {
        return {
            locale: ""
        };
    },
    watch: {
        '$i18n.locale': function (newVal, oldVal) {
            this.locale = newVal;
            moment.locale(this.locale);
        }
    },
    created() {
        this.locale = localStorage.getItem("locale");
        moment.locale(this.locale);
    },
    methods: {
        formatDate(dateString) {
            return moment(dateString, this.$getConfig("REQUEST_DATE_FORMAT")).format(this.$getConfig("DATE_FORMAT"));
        },
        formatDateForRequest(dateString) {
            return moment(dateString, this.$getConfig("DATE_FORMAT")).format(this.$getConfig("REQUEST_DATE_FORMAT"));
        }
    }
};
