<script>
import { getPaginatedArrangement, deleteArrangement } from '../../../services/arrangement.api';
import TableIndex from "../../../components/table-index";

const MODULE_NAME = "arrangement";

/**
 * Advanced table component
 */
export default {
  components: { TableIndex },
  data() {
    return {
      route: "organize." + MODULE_NAME,
      items: [],
      moduleName: MODULE_NAME,
      fields: [
        { key: "name", label: this.$t('db.field.name'), sortable: false },
        { key: "min_groups", label: this.$t('db.field.min_groups'), sortable: false },
        { key: "min_passengers", label: this.$t('db.field.min_passengers'), sortable: false },
        { key: "max_passengers", label: this.$t('db.field.max_passengers'), sortable: false },
        { key: "passengers_per_group", label: this.$t('db.field.passengers_per_group'), sortable: false },
        { key: "destination_name", label: this.$t('db.field.destination_id'), sortable: false },
        { key: "date_from", label: this.$t('db.field.date_from'), sortable: false },
        { key: "date_to", label: this.$t('db.field.date_to'), sortable: false },
        { key: 'actions', label: this.$t('table.actions') },
      ],
      header: {
        title: this.$t(`module.${MODULE_NAME}.text`),
        items: [
          {
            text: this.$t("module.home"),
            href: "/",
          },
          {
            text: this.$t(`module.${MODULE_NAME}.text`),
            active: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.$emit("input", this.header);
  },
  methods: {
    fetchDataRequest(params) {
      return getPaginatedArrangement(params);
    },
    deleteRequest(id) {
      return deleteArrangement(id);
    },
  }
};
</script>

<template>
  <div class="row">
    <TableIndex
      :route="route"
      :fields="fields"
      :moduleName="moduleName"
      :fetchDataRequest="fetchDataRequest"
      :deleteRequest="deleteRequest"
    ></TableIndex>
  </div>
</template>