import Swal from "sweetalert2";

const DISPLAING_TIMER = 2000;
const POSITION = "top-end";

export default {
    data() {
        return {};
    },
    methods: {
        fireSuccess(title) {
            this.fire("success", title);
        },
        fireError(title) {
            this.fire("error", title);
        },
        fire(icon, title){
            Swal.fire({
                position: POSITION,
                icon: icon,
                title: title,
                showConfirmButton: false,
                timer: DISPLAING_TIMER
            });
        }
    }
};
