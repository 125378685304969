import axios from 'axios'
import Qs from 'qs'
import Vue from 'vue'
//import { getToken } from '@Utils/ session.utils '// store and get the token file
import address from "./address"
import { store } from '../store/store'
import Swal from "sweetalert2";

const PREVENT_ALERT_ERRORS_LIST = [
    { code: 404, message: "There is no arrangement for that day" }
];

class Request {
    constructor () {

        // Init store
        store.commit('initialiseStore');
        // Init headers
        let headers = {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json; charset=UTF-8',
            'Access-Control-Allow-Origin': '*',
        }
        //Create Axios instance
        this._axios = axios.create({
            baseURL: address.baseURL,
            Timeout: 1000 * 5, // request timeout
            headers: {}
        })
        //Request interception
        this._axios.interceptors.request.use(
            config => {
                const requestHeader = headers
                config.headers = Object.assign(config.headers, requestHeader)
                return config
            },
            error => {
                Promise.reject(error)
            }
        )
    }
    
    //According to the request mode, it determines whether the parameters are placed in query or body.
    //The most intuitive difference, for example, the get request contains the parameters in the URL, while post places the parameters in the body through the request body, so the parameter forms are different when submitting
    //Here are four parameter forms of my common request methods. You can adjust them by yourself
    /**
      *Send get request
      *@ param {string} URL address
      *@ param {object} query query parameters
      *@ return JSON data
      */
    get (url, query = {}) {
        return this._request('get')(url, {
            ...query
        })
    }
    /**
      *Send post request
      *@ param {string} URL address
      *@ param {object} body query parameters
      *@ return JSON data
      */
    post(url, body = {}, headers) {
        let data
        if(this.isFormData(body)) {
            data = body
        } else if(Array.isArray(body)) {
            data = body
        } else {
            data = { ...body }
        }
        return this._request('post')(url, data)
    }
    put (url, body = {}) {
        return this._request('put')(url, {
            ...body
        })
    }
    delete(url, body = {}) {
        return this._request('delete')(url, {
            ...body
        })
    }

    isFormData = v => {
        return Object.prototype.toString.call(v) === '[object FormData]'
    }


    /**
      *Set request header
      *@ param {object} header request header
      */
    setHeaders (header) {
        Object.keys(header).forEach(key => {
            this._axios.defaults.headers[key] = header[key]
        })
    }

    //Processing request headers
    handleHeaders () {
        const headers = {}
        let token = store.getters.token;
        // Add access token
        if(token){
            headers['Authorization'] = "Bearer "+token
        }
        headers['XMIME-TYPE'] = '3'
        Headers['Content-Type'] = 'application/json; charset=UTF-8'
        
        return headers
    }

    /**
      *Send request
      *@ param {string} method request method type
      * @param headers
      * @returns {function(*=, *=):Promise<unknown>}
      * @private
      */
    _request (method, headers) {
        this.setHeaders ( this.handleHeaders ()) // set unified request header
        if (headers) {
            this.setHeaders (headers) // custom request header
        }
        return (url, data, timeout) => {
            const config = {
                url,
                method,
                timeout: timeout || this._axios.defaults.timeout
            }
            //Determine the request type get post
            const paramType = ['get', 'delete'].indexOf(method) !== -1 ? 'params' : 'data'
            config[paramType] = data
            //Parameter serialization
            config.paramsSerializer = params => {
                return Qs.stringify(params, { arrayFormat: 'repeat' })
            }
            
            return new Promise((resolve, reject) => {
                //Send real requests, verify permissions, check 404 and other status
                this._axios
                    .request(config)
                    .then(response => {
                        resolve(response.data);
                    }, response => {
                        //Processing error code
                        if(response.response) {
                            const statusCode = response.response.status
                            if(statusCode === 400){
                                reject(response.response);
                                return ;
                            }
                            let isRedirected = this.handleErrorStatus(statusCode, response.response)
                            if(isRedirected) {
                                reject(response)
                                return;
                            }
                        } else {
                            this.displayError(response.message);
                        }
                        reject(response)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        }
    }
    

    //The request is successful and the error code is returned
    //The specific status code is unified with the background developers, and then the corresponding prompt is given according to the status code
    //The following is my operation in the project, you can adjust the expansion
    handleSuccessStatus (code, data) {
        let result = ''
        let flag = false
        switch (code) {
            case '20007':
                Result: 'no secondary authentication password found! '
                flag = true
                break
            case '20008':
                Result: 'your secondary authentication password has not been modified, please modify it first! '
                flag = true
                break
            case '20009':
                Result: 'you have not opened the second authentication, please contact the administrator! '
                flag = true
                break
            case '90001':
                Result ='Please input the password of secondary authentication! '
                flag = true
                break
            case '90002':
                Result ='No operation permission! '
                flag = true
                break
            default:
                break
        }

        //Give notice
        //The $message method is the prompt component in the element UI that I introduced on demand. You can replace it with your own prompt component
        if (result) {
            //Vue.prototype.$message.error(result)
            this.displayError(result);
        }
        return flag
    }

    alertPreventCheck(code, message) {
        for (const item of PREVENT_ALERT_ERRORS_LIST) {
            if(item.code === code && item.message === message){
                return true;
            }
        }
        return false;
    }

    //Get the error prompt according to the error code
    handleErrorStatus(statusCode, response) {
        let errorMsg = ''

        if(this.alertPreventCheck(statusCode, response.data.error)){
            return true;
        }

        if (statusCode === 500) {
            errorMsg ='Data request failed, please contact administrator! '
        } else if (statusCode === 404) {
            errorMsg ='Request address error! '
        } else if (statusCode === 402) {
            errorMsg ='You don\'t have permission to operate this data at present! '
        } else if (statusCode === 401) {
            if("Token is Expired" === response.error){
            } else {
                window.location = "/login";
                return true;
            }
        } else {
            errorMsg ='Request error! '
        }

        this.displayError("Data request failed!", "Please contact site administrator. <br/>["+response.data.error+"]");

        return false;
    }

    displayError(title, msg) {
        Swal.fire(title, msg, "error");
    }
}

export default new Request()