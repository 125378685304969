<script>
import {
  getDeparture,
  updateDeparture,
  createDeparture,
} from "../../../services/departure.api";
import { getUsersForSelectHtmlTag } from "../../../services/user.api";
import { getArrangementForSelectHtmlTag } from "../../../services/arrangement.api";
import FormPage from "../../../components/form-page.vue";
import DepartureStatus from "../../../consts/departure.status.dbconst";
import ListHelper from "../../../mixins/list.helper.mixin";

const MODULE_NAME = "departure";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  mixins: [ListHelper],
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "departure_datetime", type: "datetime" },
        { name: "user_id", type: "select", values: [] },
        { name: "arrangement_id", type: "select", values: [] },
        { name: "status", type: "select", values: [] },
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUsersForSelectHtmlTag();
    this.getArrangementForSelectHtmlTag();
    this.fields[3].values = this.constToArray(DepartureStatus);
  },
  methods: {
    // Get action
    async getUsersForSelectHtmlTag() {
      let resp = await getUsersForSelectHtmlTag().catch((res) => {});
      this.fields[1].values = resp.data;
    },
    // Get action
    async getArrangementForSelectHtmlTag() {
      let resp = await getArrangementForSelectHtmlTag().catch((res) => {});
      this.fields[2].values = resp.data;
    },
    getRequest() {
      return getDeparture(this.$route.params.id);
    },
    updateRequest(model) {
      return updateDeparture(model);
    },
    createRequest(model) {
      return createDeparture(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>