import http from '../services/request'

const API_ENDPOINT = "agency-organizer";

/**
 *@ Get paginated list of the AgencyOrganizer
 */
export const getPaginatedAgencyOrganizer = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the AgencyOrganizer
 */
export const getAgencyOrganizer = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the AgencyOrganizer
 */
export const updateAgencyOrganizer= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new AgencyOrganizer
 */
export const createAgencyOrganizer = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the AgencyOrganizer
 */
 export const deleteAgencyOrganizer = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the AgencyOrganizer
 */
export const getAgencyOrganizerForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")