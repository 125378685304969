import http from '../services/request'

const API_ENDPOINT = "agency";

/**
 *@ Get paginated list of the Agency
 */
export const getPaginatedAgency = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Agency
 */
export const getAgency = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Agency
 */
export const updateAgency= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Agency
 */
export const createAgency = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Agency
 */
 export const deleteAgency = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Agency
 */
export const getAgenciesForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")