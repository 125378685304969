import http from '../services/request'

const API_ENDPOINT = "role";

/**
 *@ Get paginated list of the Role
 */
export const getPaginatedRole = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Role
 */
export const getRole = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Role
 */
export const updateRole= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Role
 */
export const createRole = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Role
 */
 export const deleteRole = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Role
 */
export const getRoleForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")