<script>
import { getUnreadCount } from "../services/notification.api";

export default {
  components: {},
  props: {
    actions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    bodyColor: {
      type: String,
    },
  },
  data() {
    return {
      name: "",
    };
  },
  beforeCreate: function () {
    document.getElementsByTagName("html")[0].className = "";
  },
  created() {
    this.name = this.$store.getters.user
      ? this.$store.getters.user.name_label
      : "Guest";

    // Update body color
    if (
      this.bodyColor &&
      "body-" + this.bodyColor !== document.body.className
    ) {
      document.body.className = "body-" + this.bodyColor;
    }
  },
  mounted() {
    this.getNotifications();
  },
  methods: {
    async getNotifications() {
      let resp = await getUnreadCount({}).catch((res) => {});
      if("archive" == this.actions[1].route){
        return;
      }
      if (resp && resp.total_unred_notifications > 0) {
        this.actions[1].text = "new notes";
        this.actions[1].class = "text-yellow";
      } else {
        this.actions[1].text = "notes";
        this.actions[1].class = "";
      }
    },
    goTo(actionIndex) {
      let route = "";
      let action = this.actions[actionIndex];
      if (action) {
        route = action.route;
      } else {
        return;
      }

      this.$router.push({ name: route }).catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          this.logError(err);
        }
      });
    },
    logError(err) {
      console.log("Router push error: " + err);
    },
    isLabelAction(actionIndex) {
      return this.actions[actionIndex].type === "label";
    },
  },
};
</script>

<template>
  <div class="fill">
    <div class="header">
      <div
        class="ui-button text-center align-content-center background-blue"
        :class="{ 'text-uppercase': isLabelAction(0) }"
        @click="goTo(0)"
      >
        {{ actions[0].text == "USERNAME" ? name : actions[0].text }}
      </div>
      <div
        class="ui-button text-center align-content-center background-green"
        :class="{ 'text-yellow': this.actions[1].class }"
        @click="goTo(1)"
      >
        {{ actions[1].text }}
      </div>
    </div>
    <div class="content fill"><slot /></div>
    <div class="footer-absolute">
      <div
        class="ui-button text-center align-content-center background-red"
        @click="goTo(2)"
      >
        {{ actions[2].text }}
      </div>
      <div
        class="ui-button text-center align-content-center background-yellow"
        @click="goTo(3)"
      >
        {{ actions[3].text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: white;
  z-index: 2;
  overflow: hidden;
}
.footer-absolute {
  bottom: 0;
  position: fixed;
  right: 0;
  left: 0;
  color: #74788d;
  background-color: #f2f2f5;
  overflow: hidden;
}
.content {
  margin-top: 68px;
  margin-bottom: 68px;
  overflow: auto;
}
.ui-button {
  float: left;
  width: 50%;
  font-size: 1.9rem;
  padding: 8px;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
.text-yellow {
  color: yellow;
}
</style>