<script>
import { getNotificationType, updateNotificationType, createNotificationType } from '../../../services/notificationType.api';
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "notificationType";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "name", type: "input" }
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUsersForSelectHtmlTag();
    this.getArrangementForSelectHtmlTag();
  },
  methods: {
    // Get action
    async getUsersForSelectHtmlTag() {
      let resp = await getUsersForSelectHtmlTag().catch((res) => {});
      this.fields[1].values = resp.data;
    },
    // Get action
    async getArrangementForSelectHtmlTag() {
      let resp = await getArrangementForSelectHtmlTag().catch((res) => {});
      this.fields[2].values = resp.data;
    },
    getRequest() {
      return getNotificationType(this.$route.params.id);
    },
    updateRequest(model) {
      return updateNotificationType(model);
    },
    createRequest(model) {
      return createNotificationType(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>