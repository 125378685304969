import http from '../services/request'

const API_ENDPOINT = "cancellation";

/**
 *@ Get paginated list of the Cancellation
 */
export const getPaginatedCancellation = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Cancellation
 */
export const getCancellation = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Cancellation
 */
export const updateCancellation= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Cancellation
 */
export const createCancellation = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Cancellation
 */
 export const deleteCancellation = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Cancellation
 */
export const getCancellationForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")