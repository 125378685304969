import http from '../services/request'

const API_ENDPOINT = "user";

/**
 *@ Get paginated list of the User
 */
export const getPaginatedUser = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the User
 */
export const getUser = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the User
 */
export const updateUser= (id, params) => http.put(API_ENDPOINT+"/"+id, params)

/**
 *@ Create a new User
 */
export const createUser = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the User
 */
 export const deleteUser = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the User
 */
export const getUsersForSelectHtmlTag = params => http.get(API_ENDPOINT+"/selectOptions", params)