import http from '../services/request'

const API_ENDPOINT = "cancellationType";

/**
 *@ Get paginated list of the CancellationType
 */
export const getPaginatedCancellationType = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the CancellationType
 */
export const getCancellationType = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the CancellationType
 */
export const updateCancellationType= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new CancellationType
 */
export const createCancellationType = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the CancellationType
 */
 export const deleteCancellationType = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the CancellationType
 */
export const getCancellationTypeForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")