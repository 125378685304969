
export default {
    data() {
        return {};
    },
    methods: {
        getTextByType(notification) {
            switch (notification.type) {
                case "APPLIED_BY_TRAVELER":
                    return 'You successfully applied for trip "'+notification.arrangement.name+'"';
                    break;
                case "DECLINED_BY_TRAVELER":
                    return 'You successfully canceled the trip "'+notification.arrangement.name+'"';
                    break;
                case "DECLINED_BY_ORGANIZER":
                    return 'Trip "'+notification.arrangement.name+'" has been canceled by Organizer';
                    break;
                default:
                    break;
            }
        },
        getFrom(notification){
            return notification.from ? "" : "3p2go";
        }
    }
};
