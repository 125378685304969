import http from '../services/request'

const API_ENDPOINT = "departure";

/**
 *@ Get paginated list of the Departure
 */
export const getPaginatedDeparture = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Departure
 */
export const getDeparture = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Departure
 */
export const updateDeparture= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Departure
 */
export const createDeparture = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Departure
 */
 export const deleteDeparture = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Departure
 */
export const getDeparturesForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")

/**
 *@ Get paginated list of the Departure
 */
 export const getPaginatedDeparturesByUser = (userId, params) => http.get(API_ENDPOINT+"/user/"+userId, params)