<script>
import { getArrangement, updateArrangement, createArrangement } from '../../../services/arrangement.api';
import { getUsersForSelectHtmlTag } from '../../../services/user.api';
import { getDestinationsForSelectHtmlTag } from '../../../services/destination.api';
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "arrangement";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "name", type: "input", disabled: false },
        { name: "min_groups", type: "number", disabled: false },
        { name: "min_passengers", type: "number", disabled: false },
        { name: "max_passengers", type: "number", disabled: false },
        { name: "passengers_per_group", type: "number", disabled: false },
        { name: "destination_id", type: "select", values: [], disabled: false },
        { name: "user_id", type: "select", values: [], disabled: false },
        { name: "date_from", type: "datetime", disabled: false },
        { name: "date_to", type: "datetime", disabled: false },
        { name: "days", type: "array_checkbox", values: [
          {value: "1", label: this.$t("day.1")},
          {value: "2", label: this.$t("day.2")},
          {value: "3", label: this.$t("day.3")},
          {value: "4", label: this.$t("day.4")},
          {value: "5", label: this.$t("day.5")},
          {value: "6", label: this.$t("day.6")},
          {value: "7", label: this.$t("day.7")}

        ], disabled: false },
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUsersForSelectHtmlTag();
    this.getDestinationsForSelectHtmlTag();
  },
  methods: {
    // Get action
    async getUsersForSelectHtmlTag() {
      let resp = await getUsersForSelectHtmlTag().catch((res) => {});
      this.fields[6].values = resp.data;
    },
        // Get action
    async getDestinationsForSelectHtmlTag() {
      let resp = await getDestinationsForSelectHtmlTag().catch((res) => {});
      this.fields[5].values = resp.data;
    },
    getRequest() {
      return getArrangement(this.$route.params.id);
    },
    updateRequest(model) {
      return updateArrangement(model);
    },
    createRequest(model) {
      return createArrangement(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>