export default Object.freeze({
    Filter: Object.freeze({
      ALL: "ALL",
      ARCHIVED_ONLY: "ARCHIVED_ONLY",
      UNARCHIVED_ONLY: "UNARCHIVED_ONLY",
    }),
    Status: Object.freeze({
      ALL: "ALL",
      READ_ONLY: "READ_ONLY",
      UNREAD_ONLY: "UNREAD_ONLY",
    })
  })