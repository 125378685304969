export default {
    data() {
        return {
            alertMsg: "",
            showAlert: false,
            isSuccess: false,
            alertVariant: "success"
        };
    },
    methods: {
        showErrorAlert(msg) {
            this.alertVariant = "danger";
            this.showAlert = true;
            this.alertMsg = "";
            if (typeof msg === 'object') {
                for (let item in msg) {
                    this.alertMsg += msg[item]+"\n";
                }
            } else {
                this.alertMsg = msg;
            }
        },
        showSuccessAlert(msg) {
            this.alertVariant = "success";
            this.showAlert = true;
            this.alertMsg = msg;
        },
        hideAlert() {
            this.showAlert = false;
            this.alertMsg = "";
        }   
    },
    watch: {

    }
};
