<script>
import { resetPassword } from "../services/auth.api";
import Alert from "../mixins/alerts.mixin";
import SweetAlert from "../mixins/sweetalert.helper.mixin";

export default {
  mixins: [Alert, SweetAlert],
  data() {
    return {
      errors: {},
      disabled: false,
      isAuthError: false,
      authError: "",
      model: {
        email: "",
        password: "",
        password_confirmation: "",
        token: "",
      },
    };
  },
  components: {},
  created() {
    this.model.token = this.$route.params.token;
  },
  beforeCreate: function () {
    document.body.className = "login-page";
    document.getElementsByTagName("html")[0].className = "login-page";
  },
  methods: {
    async resetPassword() {
      let res;
      this.disabled = true;
      this.errors = {};
      this.hideAlert();
      // Login action
      res = await resetPassword(this.model).catch((res) => {
        this.disabled = false;
        if ("The given data was invalid." === res.data.error) {
          if (res.data.errors) {
            this.errors = res.data.errors;
            this.showErrorAlert(this.handleErrors(res.data.errors));
          }
        }
        if (res.data.error) {
          this.showErrorAlert(this.transalteErrorMessage(res.data.error));
        }
      });

      if (res) {
        if (res.status == "Your password has been reset!") {
          this.fireSuccess(this.$t("message.password-reset-successfull"));
        } else {
          this.fireError(this.$t("message.error-proccesing-request"));
        }

        this.$store.commit("change", res);
        setTimeout(() => {
          this.$router.push({ name: "login" });
        }, 2000);
      }
    },
    handleErrors(errors) {
      let msgs = [];
      for (let field in errors) {
        let m = this.transalteErrorMessage(errors[field][0]);
        msgs.push(m);
        this.errors[field] = m;
      }

      return msgs;
    },
    transalteErrorMessage(error) {
      switch (error) {
        case "The email field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.email"),
          });
        case "The password field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.password"),
          });
        case "The email must be a valid email address.":
          return "The email must be a valid email address.";
        case "Email and password combination not recognised":
          return this.$t("message.error.wrong-email-or-password");
        default:
          return error;
      }
    },
  },
  mounted() {},
};
</script>

<template>
  <div class="login-content">
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="overflow-hidden">
              <div class="card-body pt-0 pb-0">
                <div class="p-2">
                  <b-alert
                    v-model="isAuthError"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ authError }}</b-alert
                  >
                  <b-form class="p-2" @submit.prevent="resetPassword">
                    <slot />
                    <b-form-group
                      id="input-group-1"
                      :label="$t('db.field.email')"
                      label-for="input-1"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        :class="{ 'is-invalid': errors['email'] }"
                        v-model="model.email"
                        type="email"
                        :disabled="disabled"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.email'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['email']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["email"] }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      :label="$t('db.field.password')"
                      label-for="input-2"
                      class="mb-3"
                    >
                      <b-form-input
                        id="password"
                        :class="{ 'is-invalid': errors['password'] }"
                        v-model="model.password"
                        :disabled="disabled"
                        type="password"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.password'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['password']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["password"] }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      :label="$t('db.field.password_confirmation')"
                      label-for="input-2"
                      class="mb-3"
                    >
                      <b-form-input
                        id="password"
                        :class="{
                          'is-invalid': errors['password_confirmation'],
                        }"
                        v-model="model.password_confirmation"
                        :disabled="disabled"
                        type="password"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.password_confirmation'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['password']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["password_confirmation"] }}</span>
                      </div>
                    </b-form-group>

                    <div class="mt-3 d-grid">
                      <b-button
                        variant="light"
                        class="btn-block"
                        :disabled="disabled"
                        type="submit"
                        >{{ $t("action.reset-password") }}</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}

.login-page {
  background-color: #cf9e35 !important;

  label {
    color: white;
  }
}
</style>