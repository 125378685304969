<script>
import { getPaginatedUserAgencyFavorite, deleteUserAgencyFavorite } from '../../../services/userAgencyFavorite.api';
import TableIndex from "../../../components/table-index";

const MODULE_NAME = "userAgencyFavorite";

/**
 * Advanced table component
 */
export default {
  components: { TableIndex },
  data() {
    return {
      route: "admin." + MODULE_NAME,
      items: [],
      moduleName: MODULE_NAME,
      fields: [
        { key: "agency_id", label: this.$t('db.field.agency_id'), sortable: false },
        { key: "user_id", label: this.$t('db.field.user_id'), sortable: false },
        { key: 'actions', label: this.$t('table.actions') },
      ],
      header: {
        title: this.$t(`module.${MODULE_NAME}.text`),
        items: [
          {
            text: this.$t("module.home"),
            href: "/",
          },
          {
            text: this.$t(`module.${MODULE_NAME}.text`),
            active: true,
          },
        ],
      },
    };
  },
  mounted() {
    this.$emit("input", this.header);
  },
  methods: {
    fetchDataRequest(params) {
      return getPaginatedUserAgencyFavorite(params);
    },
    deleteRequest(id) {
      return deleteUserAgencyFavorite(id);
    },
  },
};
</script>

<template>
  <div class="row">
    <TableIndex
      :route="route"
      :fields="fields"
      :moduleName="moduleName"
      :fetchDataRequest="fetchDataRequest"
      :deleteRequest="deleteRequest"
    ></TableIndex>
  </div>
</template>