import http from '../services/request'

const API_ENDPOINT = "arrangement";

/**
 *@ Get paginated list of the Arrangement
 */
export const getPaginatedArrangement = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Arrangement
 */
export const getArrangement = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Arrangement
 */
export const updateArrangement= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Arrangement
 */
export const createArrangement = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Arrangement
 */
 export const deleteArrangement = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Arrangement
 */
export const getArrangementForSelectHtmlTag = (params) => http.get(API_ENDPOINT+"/selectOptions", params)

 /**
 *@ Apply to the Arrangement
 */
 export const applyToArrangement = (id, params) => http.put(API_ENDPOINT+"/"+id+"/user", params)