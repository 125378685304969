import http from '../services/request'

const API_ENDPOINT = "notification";

/**
 *@ Get paginated list of the Notification
 */
export const getPaginatedNotification = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Notification
 */
export const getNotification = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Notification
 */
export const updateNotification = params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Notification
 */
export const createNotification = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Notification
 */
 export const deleteNotification = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Notification
 */
export const getNotificationForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")

 /**
 *@ Get the Notification
 */
 export const getUnreadCount = () => http.get(API_ENDPOINT+"/count")