import http from '../services/request'

const API_ENDPOINT = "group";

/**
 *@ Get paginated list of the Group
 */
export const getPaginatedGroup = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Group
 */
export const getGroup = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Group
 */
export const updateGroup= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Group
 */
export const createGroup = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Group
 */
 export const deleteGroup = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Group
 */
export const getGroupForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")

/**
 *@ Get stats of the Groups
 */
 export const getStats = params => http.get(API_ENDPOINT+"/stats", params)