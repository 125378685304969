<script>
export default {
  data() {
    return {};
  },
  components: {},
  methods: {},
  mounted() {},
  created() {
    if (this.$store.getters.token) {
      this.$store.commit("removeData");
    }
    this.$router.push({ name: "home" });
  },
};
</script>

<template></template>