<script>
import Layout from "../../layouts/main";
import PageHeader from "../../components/page-header";
import Vertical from "../../layouts/horizontal";
import { projectData } from "./data-projects";
import HorizontalTopbar from "../horizontal-topbar";
import HorizontalNav from "../horizontal-nav";
import RightBar from "../right-bar";
import Footer from "../footer";
/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader, Vertical, HorizontalTopbar, Footer },
  data() {
    return {
      projectData: {
        Organizer: [
          {
            buttext: "Lorem ipsum",
            butto: "",
            text: "Petar Petrovic",
            subtext: "Welcome to the our site",
          },
          {
            buttext: this.$t("module.destination.index"),
            butto: "organize.destination.index",
            text: this.$t("module.destination.text"),
            subtext: "To achieve it would be necessary",
          },
          {
            buttext: this.$t("module.arrangement.index"),
            butto: "organize.arrangement.index",
            text: this.$t("module.arrangement.index"),
            subtext: "To achieve it would be necessary",
          },
        ],
        Guest: [
          {
            buttext: "map",
            butto: "help",
          },
          {
            buttext: "badge",
            butto: "",
          },
          {
            buttext: "camp",
            butto: "register",
          },
          {
            buttext: "pack",
            butto: "login",
          },
        ],
        Traveler: [
          {
            buttext: "NAME",
            butto: "",
          },
          {
            buttext: "notes",
            butto: "",
          },
          {
            buttext: "unpack",
            butto: "logout",
          },
          {
            buttext: "plan",
            butto: "plan.trip",
          },
        ],
        Administrator: [
          {
            buttext: "Lorem ipsum",
            butto: "",
          },
          {
            buttext: "Users list",
            butto: "admin.user.index",
          },
          {
            buttext: "Levels list",
            butto: "admin.level.index",
          },
          {
            buttext: "Roles list",
            butto: "admin.role.index",
          },
        ],
      }
    };
  },
  created() {
    if (this.$store.getters.user) {
      this.projectData = this.projectData[this.$store.getters.user.role];
      this.projectData[0].buttext = this.$store.getters.user.name;
    } else {
      this.projectData = this.projectData["Guest"];
    }
  },
  mounted() {},
  beforeCreate: function () {
    document.body.className = "homepage";
    document.getElementsByTagName("html")[0].className = "html-homepage";
  },
  methods: {
    goTo(route) {
      this.$router.push({ name: route }).catch((err) => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (
          err.name !== "NavigationDuplicated" &&
          !err.message.includes(
            "Avoided redundant navigation to current location"
          )
        ) {
          // But print any other errors to the console
          logError(err);
        }
      });
      // let props = this.$router.resolve({
      //   name: route,
      // });

      // window.location=props.href;
    },
  },
};
</script>
<template>
  <div id="layout-wrapper">
    <div class="main-content">
      <div class="page-content-home">
        <div class="fill">
          <div
            class="text-center align-content-center container"
            :class="{
              'map-4': projectData.length == 4,
              'map-6': projectData.length == 6,
            }"
            v-for="(grid, index) in projectData"
            :key="grid.text"
            :id="'grid-' + (index + 1)"
            @click="goTo(grid.butto)"
          >
            <router-link
              :to="{ name: grid.butto }"
              class="btn btn-light btn-lg"
              >{{ grid.buttext }}</router-link
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.homepage,
.html-homepage {
  height: 100%;
}

.map-6 {
  width: 50%;
  height: 33.333333%;
  float: left;
  min-height: 33.333333%;
  background: white;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.map-4 {
  width: 50%;
  height: 50%;
  float: left;
  min-height: 50%;
  background: white;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.fill,
#layout-wrapper,
.main-content,
.page-content-home,
container-fluid {
  min-height: 100%;
  height: 100%;
  margin-left: 0;
}

.page-content-home {
  padding: 0px !important;
}

.footer {
  left: 0;
}

.btn-lg {
  font-size: 2rem;
}

#grid-1 {
  background-color: #2e69c7;
}
#grid-2 {
  background-color: #468628;
}
#grid-3 {
  background-color: #ce3740;
}
#grid-4 {
  background-color: #cf9e35;
}
#grid-5 {
  background-color: #2e69c7;
}
#grid-6 {
  background-color: #468628;
}
</style>