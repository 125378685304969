<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import {
  getDestinationGroups,
  getDestinationsForSelectHtmlTag,
} from "../../services/destination.api";
import {
  applyToArrangement,
  getArrangementForSelectHtmlTag,
} from "../../services/arrangement.api";
import SweetAlert from "../../mixins/sweetalert.helper.mixin";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  mixins: [SweetAlert],
  data() {
    return {
      upcomingTasks: [],
      destination_id: "",
      destinations: [],
      departure: {},
      arrangement: {},
      arrangements: [],
      arrangement_id: "",
      meInTheDeparture: true,
      numOfpassengers: 1,
      maxNumOfpassengers: 3,
      showPlaceHolder: true,
      showNoRecordsInfo: false,
      bodyColor: "orange",
      headerFooter: {
        actions: this.$getConfig("TRAVELER_LAYOUT_BUTTONS").concat([
          { type: "route", text: "back", route: "plan.trip" },
        ]),
      },
    };
  },
  created() {
    this.maxNumOfpassengers = this.$store.getters.user.benefits.max_passengers;
  },
  mounted() {
    this.getDestinationsForSelectHtmlTag();
  },
  methods: {
    onDestinationChange() {
      this.getArrangementForSelectHtmlTag();
    },
    onArrangementChange() {
      this.fetchData();
    },
    async apply() {
      let data = {
        user_id: "me",
        num_of_passengers: this.numOfpassengers,
        departure_datetime: this.$route.params.date,
      };

      let resp = await applyToArrangement(this.arrangement.id, data).catch(
        (res) => {
          if (
            "The given data was invalid." === res.data.message &&
            "The user already applied for the arrangement" ===
              res.data.errors.user_id[0]
          ) {
            this.fireError(this.$t("message.error-already-in-arrangement"));
          } else {
            this.fireError(this.$t("message.error-proccesing-request"));
          }
        }
      );

      if (resp === "") {
        this.fireSuccess(this.$t("message.added-in-departure-successfull"));
        this.meInTheDeparture = true;
        this.$refs.layout.getNotifications();
        this.fetchData();
      }
    },
    processResponse(response) {
      this.showPlaceHolder = false;
      if (!response || !response.data) {
        return;
      }
      this.upcomingTasks = response.data;
      this.meInTheDeparture = response.me_in_the_departure;
      this.departure = response.departure;
      this.arrangement = response.arrangement;
    },
    // Get destinations
    async getDestinationsForSelectHtmlTag() {
      let resp = await getDestinationsForSelectHtmlTag().catch((res) => {});
      if (resp.data.length === 0) {
        this.showNoRecordsInfo = true;

        return;
      }
      this.showNoRecordsInfo = false;
      this.destinations = resp.data;
      this.destination_id = this.destinations[0].id;
      this.getArrangementForSelectHtmlTag();
    },
    // Get arrangements
    async getArrangementForSelectHtmlTag() {
      let data = {
        destination_id: this.destination_id,
      };
      this.arrangements = [];
      let resp = await getArrangementForSelectHtmlTag(data).catch((res) => {});
      if (resp.data.length === 0) {
        this.showNoRecordsInfo = true;

        return;
      }
      this.showNoRecordsInfo = false;
      this.arrangements = resp.data;
      this.arrangement_id = this.arrangements[0].id;
      this.fetchData();
    },
    async fetchData() {
      this.tableData = [];
      this.showPlaceHolder = true;
      let res = await getDestinationGroups(this.destination_id, "me", {
        date: this.$route.params.date,
      }).catch((res) => {
        this.showNoRecordsInfo = true;
      });
      setTimeout(() => {
        this.processResponse(res);
      }, 1000);
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor" ref="layout">
    <div class="content-body">
      <div v-if="showNoRecordsInfo" class="card-body">
        <span class="drag-column-header">
          <h4 class="card-title mb-0">
            There are no arrangements for the selected destination!
          </h4>
        </span>
      </div>
      <div v-if="!showNoRecordsInfo" class="card-body">
        <span class="drag-column-header">
          <h4 v-if="!showPlaceHolder" class="card-title mb-4 pb-1">
            {{ arrangement.name }}
          </h4>
          <p v-if="!showPlaceHolder">{{ arrangement.name }}</p>
          <p v-if="!showPlaceHolder">
            Passengers per group: {{ arrangement.passengers_per_group }} /
            min_groups: {{ arrangement.min_groups }} / min_passengers:
            {{ arrangement.min_passengers }}
          </p>
          <p v-if="showPlaceHolder">
            <span class="placeholder col-4"></span>
          </p>
          <p v-if="showPlaceHolder">
            <span class="placeholder col-1"></span>/
            <span class="placeholder col-1"></span>/
            <span class="placeholder col-1"></span>
          </p>
        </span>
        <div v-if="showPlaceHolder" class="col-lg-12">
          <div
            v-for="index in 1"
            :key="index"
            class="card shadow-none border mb-0"
            aria-hidden="true"
          >
            <div class="card-body">
              <h5 class="card-title placeholder-glow">
                <span class="placeholder col-6"></span>
              </h5>
              <p class="card-text placeholder-glow">
                <span class="placeholder col-7"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-4"></span>
                <span class="placeholder col-6"></span>
                <span class="placeholder col-8"></span>
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="!showPlaceHolder"
          class="list-group"
          group="tasks"
          :list="upcomingTasks"
        >
          <div
            v-for="task in upcomingTasks"
            :key="task.id"
            class="card task-box"
          >
            <div class="card-body">
              <div class="float-end ml-2">
                <span
                  class="badge rounded-pill font-size-12"
                  :class="{
                    'badge-soft-primary':
                      task.total_users < arrangement.passengers_per_group,
                    'badge-soft-danger':
                      task.total_users >= arrangement.passengers_per_group,
                  }"
                  >{{
                    task.total_users >= arrangement.passengers_per_group
                      ? "FULL"
                      : "NOT FULL"
                  }}</span
                >
              </div>
              <div>
                <h5 class="font-size-15">
                  <a href="javascript: void(0);" class="text-dark">{{
                    task.name
                  }}</a>
                </h5>
              </div>
              <p class="text-muted mb-0">
                Passengers in group: {{ task.total_users }} ({{
                  arrangement.passengers_per_group
                }}
                max)
              </p>
            </div>
          </div>
        </div>
        <div class="text-center d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-block mt-3 btn-apply"
            v-if="!meInTheDeparture"
            @click="apply"
          >
            {{ $t("action.apply-arrangement") }}
          </a>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-4 mb-2 mb-lg-0">
          <select
            size="lg"
            class="form-control"
            v-model="destination_id"
            @change="onDestinationChange"
          >
            <option
              v-for="item in destinations"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-4 mb-2 mb-lg-0">
          <select
            size="lg"
            class="form-select custom-select"
            v-model="arrangement_id"
            @change="onArrangementChange"
          >
            <option
              v-for="item in arrangements"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </option>
          </select>
        </div>
        <div class="col-lg-4">
          <b-form-input
            size="lg"
            v-model="numOfpassengers"
            value="1"
            type="number"
            :min="1"
            :max="maxNumOfpassengers"
          ></b-form-input>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.content-body {
  background-color: yellow;
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin: 10px;
  padding: 6px;
}
.btn-apply {
  font-size: 1.9rem;
}
</style>