import http from '../services/request'

const API_ENDPOINT = "destination";

/**
 *@ Get paginated list of the Destination
 */
export const getPaginatedDestination = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the Destination
 */
export const getDestination = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the Destination
 */
export const updateDestination= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new Destination
 */
export const createDestination = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the Destination
 */
 export const deleteDestination = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the Destination
 */
export const getDestinationsForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")

/**
 *@ Get paginated list of the Destination
 */
 export const getDestinationGroups = (id, user, params) => http.get(API_ENDPOINT+"/"+id+"/user/"+user+"/groups", params)