<script>
import { getLevel, updateLevel, createLevel } from '../../../services/level.api';
import Alert from "../../../mixins/alerts.mixin";
import SweetAlert from "../../../mixins/sweetalert.helper.mixin";

/**
 * Advanced table component
 */
export default {
  components: {},
  mixins: [Alert, SweetAlert],
  props: ['isCreateForm', 'isEditForm', 'isShowForm'],
  data() {
    return {
      model: {},
      
      title: this.$t('module.level.text'),
      cardTitle: this.$t('module.level.create'),
      cardDescription: "",
      disabled: false,
      items: [
        {
          text: this.$t('module.home'),
          href: "/",
        },
        {
          text: this.$t('module.level.text'),
          active: true,
        },
      ],
    submitted: true,
    buttonLabel: this.$t('action.create'),
    errors: {

    }
    }
  },
    async created(){
      // Edit page
      if(this.isEditForm || this.isShowForm){
        this.cardTitle = this.$t('module.level.edit');
        let res = await getLevel(this.$route.params.id);
        if(res){
          this.model = res;
        }
        this.buttonLabel = this.$t('action.update');
      }
      // Show page
      if(this.isShowForm){
        this.cardTitle = this.$t('module.level.show');
        this.disabled = true;
        this.buttonLabel = null;
      }
      
    },
    methods:{
        
        async updateLevel(){
          let res;
          if(this.isEditForm){
             // Update action
            res = await updateLevel(this.model).catch(res => {
              this.errors = res.data.errors;
              let msg = "";
              Object.values(this.errors).forEach(val => {
                msg += val;
              });
              this.showErrorAlert(msg);
            });
          } else if(this.isCreateForm){
            // Create action
            res = await createLevel(this.model).catch(res => {
              this.errors = res.data.errors;
              let msg = "";
              Object.values(this.errors).forEach(val => {
                msg += val;
              });
              this.showErrorAlert(msg);
            });
          }
          if(res){
            let message = (this.isCreateForm) ? this.$t("message.created-successfull") : this.$t("message.updated-successfull");
            this.fireSuccess(message);
            setTimeout(() => {  this.$router.push({name: 'admin.level.index'}); }, 2000);
          }
        }
    }
}
  </script>

  <template>
     <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">{{ cardTitle }}</h4>
            <p class="card-title-desc">
              {{ cardDescription }}
            </p>
              <b-alert v-model="showAlert" dismissible :variant="alertVariant">
                <i class="mdi mdi-block-helper me-2"></i>{{ alertMsg }}
              </b-alert>
            <div class="row">
              <div class="col-12">
                <form class="form-horizontal" role="form" @submit.prevent="updateLevel">
                  <b-form-group class="mb-3" id="name" label-cols-lg="2" :label="$t('db.field.name')" label-for="name">
                    <b-form-input for="text" :disabled="disabled == true" value=""  v-model="model.name" :class="{ 'is-invalid': errors.name }"></b-form-input>
                    <div v-if="errors.name " class="invalid-feedback">
                        <span>{{ errors.name }}</span>
                    </div>
                  </b-form-group>
                  <button v-if="buttonLabel" type="submit" class="btn btn-primary">{{ buttonLabel}}</button>
                  <router-link :to="{name: 'admin.level.index'}" class="btn btn-success">{{ $t('action.back') }}</router-link>
                </form>
              </div>
            </div>
            <!-- end row -->
          </div>
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
</template>