<script>
import Alert from "../mixins/alerts.mixin";
import SweetAlert from "../mixins/sweetalert.helper.mixin";
import DateFormat from "../mixins/dateformat.helper.mixin";

/**
 * Table index component
 */
export default {
  components: {},
  mixins: [Alert, SweetAlert, DateFormat],
  props: {
    route: {
      type: String,
      default: "",
    },
    moduleName: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    getRequest: {
      type: Function,
    },
    updateRequest: {
      type: Function,
    },
    createRequest: {
      type: Function,
    },
    isEditForm: {
      type: Boolean,
      default: "",
      default: false,
    },
    isCreateForm: {
      type: Boolean,
      default: "",
      default: false,
    },
    isShowForm: {
      type: Boolean,
      default: "",
      default: false,
    },
  },
  data() {
    return {
      model: {},
      disabled: false,
      submitted: true,
      buttonLabel: this.$t("action.create"),
      errors: {},
      cardTitle: this.$t(`module.${this.moduleName}.create`),
      cardDescription: "",
      options: {
        format: this.$getConfig("DATE_FORMAT"),
        useCurrent: false,
        sideBySide: true,
      },
    };
  },
  methods: {
    updateRole() {
      let res;
      let data = this.getDataForSubmit();
      if (this.isEditForm) {
        // Update action
        this.updateRequest(data)
          .then(this.handleResponse)
          .catch(this.handleCatchResponse);
      } else if (this.isCreateForm) {
        // Create action
        this.createRequest(data)
          .then(this.handleResponse)
          .catch(this.handleCatchResponse);
      }
    },
    /**
     * Update and Create handler
     */
    handleResponse(res) {
      let message = this.isCreateForm
        ? this.$t("message.created-successfull")
        : this.$t("message.updated-successfull");
      this.fireSuccess(message);
      setTimeout(() => {
        this.$router.push({ name: `${this.route}.index` });
      }, 2000);
    },
    /**
     * Catch handler
     */
    handleCatchResponse(res) {
      this.errors = res.data ? res.data.errors : [];
      let msg = "";
      Object.values(this.errors).forEach((val) => {
        msg += val;
      });
      this.showErrorAlert(msg);
    },
    /**
     * Get response handler
     */
    handleGetResponse(res) {
      if (res) {
        this.model = res;
        // Format model data for displaing
        this.formatDataForDispling();
      }
    },
    formatDataForDispling() {
      for (let [i, field] of this.fields.entries()) {
        if (field.type === "datetime") {
          let d = this.model[field.name];
          this.model[field.name] = this.formatDate(this.model[field.name]);
        }
      }
    },
    getDataForSubmit() {
      let data = Object.assign({}, this.model);
      for (let [i, field] of this.fields.entries()) {
        if (field.type === "datetime") {
          let date = this.formatDateForRequest(data[field.name]);
          if ("Invalid date" === date) {
            alert("Can't convert date: " + data[field.name]);
          }
          data[field.name] = date;
        }
      }

      return data;
    },
    initArrayParams() {
      for (let [i, field] of this.fields.entries()) {
        if (field.type === "array_checkbox" && this.isCreateForm) {
          this.model[field.name] = [];
        }
      }
    }
  },
  mounted() {},
  created() {
    // Edit page
    if (this.isEditForm || this.isShowForm) {
      this.cardTitle = this.$t("module.role.edit");
      this.getRequest().then(this.handleGetResponse);
      this.buttonLabel = this.$t("action.update");
    }
    // Show page
    if (this.isShowForm) {
      this.cardTitle = this.$t("module.role.show");
      this.disabled = true;
      this.buttonLabel = null;
    }

    if (this.isCreateForm) {
      this.initArrayParams();
    }
  },
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <h4 class="card-title">{{ cardTitle }}</h4>
          <p class="card-title-desc">
            {{ cardDescription }}
          </p>
          <b-alert v-model="showAlert" dismissible :variant="alertVariant">
            <i class="mdi mdi-block-helper me-2"></i>{{ alertMsg }}
          </b-alert>
          <div class="row">
            <div class="col-12">
              <form
                class="form-horizontal"
                role="form"
                @submit.prevent="updateRole"
              >
                <div v-for="field in fields" :key="field.name">
                  <b-form-group
                    v-if="field.type == 'input'"
                    class="mb-3"
                    id="field.name"
                    label-cols-lg="2"
                    :label="$t('db.field.' + field.name)"
                    label-for="field.name"
                  >
                    <b-form-input
                      for="text"
                      :disabled="disabled == true || field.disabled"
                      value=""
                      v-model="model[field.name]"
                      :class="{ 'is-invalid': errors[field.name] }"
                    ></b-form-input>
                    <div v-if="errors[field.name]" class="invalid-feedback">
                      <span>{{ errors[field.name] }}</span>
                    </div>
                  </b-form-group>

                  <div class="mb-3 row" v-if="field.type == 'select'">
                    <label class="col-md-2 col-form-label">{{
                      $t("db.field." + field.name)
                    }}</label>
                    <div class="col-md-10">
                      <select
                        class="form-control"
                        v-model="model[field.name]"
                        :disabled="disabled == true || field.disabled"
                        :class="{ 'is-invalid': errors[field.name] }"
                      >
                        <option
                          v-for="item in field.values"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                    <div v-if="errors[field.name]" class="invalid-feedback">
                      <span>{{ errors[field.name] }}</span>
                    </div>
                  </div>

                  <b-form-group
                    v-if="field.type == 'number'"
                    class="mb-3"
                    :id="field.name"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    :label="$t('db.field.' + field.name)"
                    :labelFor="field.name"
                  >
                    <b-form-input
                      v-model="model[field.name]"
                      :disabled="disabled == true"
                      :id="field.name"
                      value=""
                      type="number"
                      :name="field.name"
                    ></b-form-input>
                    <div v-if="errors[field.name]" class="invalid-feedback">
                      <span>{{ errors[field.name] }}</span>
                    </div>
                  </b-form-group>

                  <b-form-group
                    v-if="field.type == 'datetime'"
                    class="mb-3"
                    :id="field.name"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    :label="$t('db.field.' + field.name)"
                    :labelFor="field.name"
                  >
                    <date-picker
                      v-model="model[field.name]"
                      :disabled="disabled == true"
                      :config="options"
                    ></date-picker>
                  </b-form-group>

                  <b-form-group
                    v-if="field.type == 'array_checkbox'"
                    class="mb-3"
                    :id="field.name"
                    label-cols-sm="2"
                    label-cols-lg="2"
                    :label="$t('db.field.' + field.name)"
                    :labelFor="field.name"
                  >
                    <b-form-checkbox-group
                      v-model="model[field.name]"
                      :options="field.values"
                      class="mb-3"
                      value-field="value"
                      text-field="label"
                      :disabled="disabled == true"
                      plain
                      stacked
                    ></b-form-checkbox-group>
                  </b-form-group>
                </div>

                <button
                  v-if="buttonLabel"
                  type="submit"
                  class="btn btn-primary"
                >
                  {{ buttonLabel }}
                </button>
                <router-link
                  :to="{ name: `${route}.index` }"
                  class="btn btn-success"
                  >{{ $t("action.back") }}</router-link
                >
              </form>
            </div>
          </div>
          <!-- end row -->
        </div>
      </div>
      <!-- end card -->
    </div>
    <!-- end col -->
  </div>
  <!-- end row -->
</template>
