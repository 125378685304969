<script>
import Vertical from "./horizontal";
import PageHeader from "../components/page-header";

export default {
  components: { Vertical, PageHeader },
  data() {
    return {};
  },
  computed: {
    layoutType() {
      return this.$root.layout?.type;
    },
  },
  methods: {},
  mounted() {},
  beforeCreate: function () {
    document.body.className = "";
    document.getElementsByTagName("html")[0].className = "";
  }
};
</script>

<template>
  <div>
    <Vertical v-if="layoutType === 'vertical'">
      <slot />
    </Vertical>
  </div>
</template>