<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import moment from "moment";
import SweetAlert from "../../mixins/sweetalert.helper.mixin";
import {
  getGroupUsers,
  deleteUserFromGroup,
} from "../../services/groupUser.api";

import { getPaginatedDeparturesByUser } from "../../services/departure.api";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  mixins: [SweetAlert],
  data() {
    return {
      trips: [],
      itemPosition: 0,
      items: [],
      bodyColor: "orange",
      headerFooter: {
        actions: this.$getConfig("TRAVELER_LAYOUT_BUTTONS").concat([
          { type: "route", text: "trip", route: "plan.trip" },
        ]),
      },
    };
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    moment.locale(locale);
    this.fetchData();
  },
  methods: {
    async leaveGroup(groupId) {
      let res = await deleteUserFromGroup(groupId, "me");
      let i = this.trips.map((data) => data.group_id).indexOf(groupId);
      this.trips.splice(i, 1);
      this.fireSuccess(this.$t("message.deleted-successfull"));
      this.$refs.layout.getNotifications();
    },
    async fetchData() {
      this.trips = [];
      let res = await getGroupUsers("me", {
        page: this.currentPage,
        perPage: this.perPage,
      });
      this.processResponse(res);
    },
    processResponse(response) {
      if (!response.data) {
        return;
      }
      this.trips = response.data;
      this.totalRows = response.totalRows;
      this.currentPage = response.currentPage;

      for (let [i, trip] of this.trips.entries()) {
        trip.class = this.getClass();
        trip.cols = Math.floor(Math.random() * (6 - 3) + 3);
        let d = moment(trip.departure.departure_datetime);
        trip.departure.departure_datetime =
          d.format("ddd ").toUpperCase() + d.format("D. MMMM");
      }
    },
    getClass() {
      this.itemPosition++;

      if (this.itemPosition == 1) {
        return "start";
      }
      if (this.itemPosition == 3) {
        this.itemPosition = 0;
        return "end";
      }
      if (this.itemPosition == 2) {
        return "center";
      }
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor" ref="layout">
    <div class="content-body">
      <div class="day-row p-0 m-0">
        <b-row v-for="trip in trips" :key="trip.group_id" :align-h="trip.class">
          <b-col :cols="trip.cols" class="p-2">
            <h5 class="font-size-15">
              <a href="javascript: void(0);" class="text-dark"
                ><i class="bx bx-calendar"></i> {{ trip.group.name }} ({{
                  trip.destination.name
                }})
              </a>
            </h5>
            <p class="mb-0">
              {{ trip.departure.departure_datetime }}
            </p>
            <a
              href="javascript: void(0);"
              class="btn btn-danger btn-block mt-3"
              v-if="'ACTIVE' === trip.departure.status"
              @click="leaveGroup(trip.group_id)"
            >
              <i class="mdi mdi-plus mr-1"></i> Leave
            </a>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.calendar-actions > div > .btn {
  font-size: 1.9rem !important;
}
.day-row > .row {
  margin-left: 0;
  margin-right: 0;
}
.day-row > .row > div {
  background-color: yellow;
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin-bottom: 10px;
}
.list-group-item {
  background-color: transparent;
  border: 0;
}
.content-body {
  height: 100%;
}
</style>