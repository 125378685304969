var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"login-content"},[_c('div',{staticClass:"account-pages my-5 pt-5"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-8 col-lg-6 col-xl-5"},[_c('div',{staticClass:"overflow-hidden"},[_c('div',{staticClass:"card-body pt-0 pb-0"},[_c('div',{staticClass:"p-2"},[_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":""},model:{value:(_vm.isAuthError),callback:function ($$v) {_vm.isAuthError=$$v},expression:"isAuthError"}},[_vm._v(_vm._s(_vm.authError))]),_vm._v(" "),_c('b-form',{staticClass:"p-2",on:{"submit":function($event){$event.preventDefault();return _vm.registerUser.apply(null, arguments)}}},[_vm._t("default"),_vm._v(" "),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":_vm.$t('db.field.email'),"label-for":"input-1"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.errors['email'] },attrs:{"id":"input-1","disabled":_vm.disabled,"placeholder":_vm.$t('message.enter-field', {
                          field: _vm.$t('db.field.email'),
                        })},model:{value:(_vm.model.email),callback:function ($$v) {_vm.$set(_vm.model, "email", $$v)},expression:"model.email"}}),_vm._v(" "),(_vm.errors['email'])?_c('div',{staticClass:"invalid-feedback text-light"},[_c('span',[_vm._v(_vm._s(_vm.errors["email"]))])]):_vm._e()],1),_vm._v(" "),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-1","label":_vm.$t('db.field.name'),"label-for":"input-1"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.errors['name'] },attrs:{"id":"input-1","disabled":_vm.disabled,"placeholder":_vm.$t('message.enter-field', {
                          field: _vm.$t('db.field.name'),
                        })},model:{value:(_vm.model.name),callback:function ($$v) {_vm.$set(_vm.model, "name", $$v)},expression:"model.name"}}),_vm._v(" "),(_vm.errors['name'])?_c('div',{staticClass:"invalid-feedback text-light"},[_c('span',[_vm._v(_vm._s(_vm.errors["name"]))])]):_vm._e()],1),_vm._v(" "),_c('b-form-group',{staticClass:"mb-3",attrs:{"id":"input-group-2","label":_vm.$t('db.field.password'),"label-for":"input-2"}},[_c('b-form-input',{class:{ 'is-invalid': _vm.errors['password'] },attrs:{"id":"password","disabled":_vm.disabled,"type":"password","placeholder":_vm.$t('message.enter-field', {
                          field: _vm.$t('db.field.password'),
                        })},model:{value:(_vm.model.password),callback:function ($$v) {_vm.$set(_vm.model, "password", $$v)},expression:"model.password"}}),_vm._v(" "),(_vm.errors['password'])?_c('div',{staticClass:"invalid-feedback text-light"},[_c('span',[_vm._v(_vm._s(_vm.errors["password"]))])]):_vm._e()],1),_vm._v(" "),_c('div',[_c('p',{staticClass:"mb-0 text-light",domProps:{"innerHTML":_vm._s(
                        _vm.$t('message.by-registering', {
                          tou_link: ("<a class='text-light' href='/link.html'>" + (_vm.$t(
                            'message.terms-of-use'
                          )) + "</a>"),
                        })
                      )}})]),_vm._v(" "),_c('div',{staticClass:"mt-3 d-grid"},[_c('b-button',{staticClass:"btn-block",attrs:{"variant":"light","disabled":_vm.disabled,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("action.register")))])],1),_vm._v(" "),_c('div',{staticClass:"mt-3 d-grid"},[_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{ name: 'home' }}},[_vm._v(_vm._s(_vm.$t("action.back-to-dashboard")))])],1)],2)],1)])]),_vm._v(" "),_c('div',{staticClass:"text-center text-light"},[_c('p',[_vm._v("\n              "+_vm._s(_vm.$t("message.have-account"))+"\n              "),_c('a',{staticClass:"fw-medium text-light",attrs:{"href":"/login"}},[_vm._v(_vm._s(_vm.$t("message.sigin-now")))])])])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }