<script>
import { login, sendResetPasswordEmail } from "../services/auth.api";
import Alert from "../mixins/alerts.mixin";
import SweetAlert from "../mixins/sweetalert.helper.mixin";

export default {
  mixins: [Alert, SweetAlert],
  data() {
    return {
      errors: {},
      disabled: false,
      isResetPassword: false,
      isAuthError: false,
      authError: "",
      model: {
        email: "",
        password: "",
      },
    };
  },
  components: {},
  created() {},
  beforeCreate: function () {
    document.body.className = "body-orange";
  },
  methods: {
    async resetPassword() {
      let res = await sendResetPasswordEmail(this.model).catch((res) => {});
      if (res) {
        this.isResetPassword = false;
        switch (res.status) {
          case "We have emailed your password reset link!":
            this.fireSuccess(this.$t("message.email-sent-successfull"));
            break;
          case "Please wait before retrying.":
            this.fireError("Please wait before retrying.");
            break;
          default:
            break;
        }
      }
    },
    async loginUser() {
      let res;
      this.disabled = true;
      this.errors = {};
      this.hideAlert();
      // Login action
      res = await login(this.model).catch((res) => {
        this.disabled = false;
        if ("The given data was invalid." === res.data.error) {
          if (res.data.errors) {
            this.errors = res.data.errors;
            this.showErrorAlert(this.handleErrors(res.data.errors));
          }
        }
        
        if ("Email and password combination not recognised" === res.data.error) {
          console.log(res);
          this.showErrorAlert(this.$t('message.error.login-error'));
        }
        if (res.data.error) {
          this.showErrorAlert(this.transalteErrorMessage(res.data.error));
        }
      });

      if (res) {
        this.fireSuccess(this.$t("message.logged-successfull"));
        this.$store.commit("change", res);
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 2000);
      }
    },
    handleErrors(errors) {
      let msgs = [];
      for (let field in errors) {
        let m = this.transalteErrorMessage(errors[field][0]);
        msgs.push(m);
        this.errors[field] = m;
      }

      return msgs;
    },
    transalteErrorMessage(error) {
      switch (error) {
        case "The email field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.email"),
          });
        case "The password field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.password"),
          });
        case "The email must be a valid email address.":
          return "The email must be a valid email address.";
        case "Email and password combination not recognised":
          return this.$t("message.error.wrong-email-or-password");
        default:
          return error;
      }
    },
  },
  mounted() {
    //document.body.classList.add("auth-body-bg");
  },
};
</script>

<template>
  <div class="login-content">
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="overflow-hidden">
              <div class="card-body pt-0 pb-0">
                <div class="p-2">
                  <b-alert
                    v-model="showAlert"
                    variant="danger"
                    class="mt-3"
                    dismissible
                    >{{ alertMsg }}</b-alert
                  >
                  <b-form
                    v-if="!isResetPassword"
                    class="p-2"
                    @submit.prevent="loginUser"
                  >
                    <slot />
                    <b-form-group
                      id="input-group-1"
                      :label="$t('db.field.email')"
                      label-for="input-1"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        :class="{ 'is-invalid': errors['email'] }"
                        v-model="model.email"
                        type="email"
                        :disabled="disabled"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.email'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['email']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["email"] }}</span>
                      </div>
                    </b-form-group>

                    <b-form-group
                      id="input-group-2"
                      :label="$t('db.field.password')"
                      label-for="input-2"
                      class="mb-3"
                    >
                      <b-form-input
                        id="password"
                        :class="{ 'is-invalid': errors['password'] }"
                        v-model="model.password"
                        :disabled="disabled"
                        type="password"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.password'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['password']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["password"] }}</span>
                      </div>
                    </b-form-group>
                    <b-form-checkbox
                      class="form-check"
                      id="customControlInline"
                      name="checkbox-1"
                      value="accepted"
                      unchecked-value="not_accepted"
                    >
                      {{ $t("db.field.remember_me") }}
                    </b-form-checkbox>
                    <div class="mt-3 d-grid">
                      <b-button
                        variant="light"
                        class="btn-block"
                        :disabled="disabled"
                        type="submit"
                        >{{ $t("action.log-in") }}</b-button
                      >
                    </div>
                    <div class="mt-3 d-grid">
                      <router-link
                        :to="{ name: 'home' }"
                        class="btn btn-primary"
                        >{{ $t("action.back-to-dashboard") }}</router-link
                      >
                    </div>
                    <div class="mt-4 text-center">
                      <a
                        href="#"
                        @click.prevent="isResetPassword = true"
                        class="text-light"
                      >
                        <i class="mdi mdi-lock mr-1"></i>
                        {{ $t("action.forgot-password") }}
                      </a>
                    </div>
                  </b-form>

                  <b-form
                    v-if="isResetPassword"
                    class="p-2"
                    @submit.prevent="resetPassword"
                  >
                    <p class="text-light">
                      {{ $t("message.forgot-text") }}
                    </p>
                    <b-form-group
                      id="input-group-1"
                      label="Email"
                      label-for="input-1"
                      class="mb-3"
                    >
                      <b-form-input
                        id="email"
                        :class="{ 'is-invalid': errors['email'] }"
                        v-model="model.email"
                        type="email"
                        :disabled="disabled"
                        :placeholder="
                          $t('message.enter-field', {
                            field: $t('db.field.email'),
                          })
                        "
                      ></b-form-input>
                      <div
                        v-if="errors['email']"
                        class="invalid-feedback text-light"
                      >
                        <span>{{ errors["email"] }}</span>
                      </div>
                    </b-form-group>

                    <div class="mt-3 d-grid">
                      <b-button
                        variant="light"
                        class="btn-block"
                        :disabled="disabled"
                        type="submit"
                        >{{ $t("action.send-email") }}</b-button
                      >
                    </div>
                    <div class="mt-3 d-grid">
                      <b-button
                        variant="light"
                        class="btn-block"
                        :disabled="disabled"
                        type="submit"
                        @click.prevent="isResetPassword = false"
                        >{{ $t("action.cancel") }}</b-button
                      >
                    </div>
                  </b-form>
                </div>
              </div>
            </div>
            <div class="text-center text-light">
              <p>
                {{ $t("message.dont-have-account") }}
                <a href="/register" class="fw-medium text-light">{{
                  $t("message.signup-now")
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}

label {
  color: white;
}
</style>