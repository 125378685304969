<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import moment from "moment";
import NotificationHelper from "../../mixins/notification.mixin";
import { getPaginatedNotification } from "../../services/notification.api";
import NotificationRequest from "../../consts/notification-request";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  mixins: [NotificationHelper],
  props: ["isArchive"],
  data() {
    return {
      notifications: [],
      itemPosition: 0,
      items: [],
      bodyColor: "green",
      filter: NotificationRequest.Filter.ALL,
      status: NotificationRequest.Status.ALL,
      headerFooter: {},
    };
  },
  mounted() {
    let locale = localStorage.getItem("locale");
    moment.locale(locale);
  },
  created() {
    this.componentUpdated();
    // react to params changes in the same component
    this.$watch(
      () => this.$route.params,
      (toParams, previousParams) => {
        this.componentUpdated();
      }
    );
  },
  methods: {
    /**
     * Component updated method
     */
    componentUpdated() {
      if (this.isArchive) {
        // Archive page
        this.filter = NotificationRequest.Filter.ARCHIVED_ONLY;
        this.headerFooter.actions = this.$getConfig(
          "TRAVELER_LAYOUT_BUTTONS"
        ).concat([{ type: "route", text: "back", route: "notes" }]);
      } else {
        // Notes page
        this.filter = NotificationRequest.Filter.UNARCHIVED_ONLY;
        this.headerFooter.actions = this.$getConfig(
          "TRAVELER_LAYOUT_BUTTONS_WITH_ARCHIVE"
        ).concat([{ type: "route", text: "back", route: "trips" }]);
      }
      this.itemPosition = 0;
      this.fetchData();
    },
    /**
     * Fetch notification data with pagination
     */
    async fetchData() {
      this.notifications = [];
      let res = await getPaginatedNotification({
        page: this.currentPage,
        perPage: this.perPage,
        filter: this.filter,
        status: this.status,
      });
      this.processResponse(res);
    },
    /**
     * Process Response
     */
    processResponse(response) {
      if (!response.data) {
        return;
      }
      this.notifications = response.data;
      this.totalRows = response.totalRows;
      this.currentPage = response.currentPage;

      for (let [i, notification] of this.notifications.entries()) {
        notification.class = this.getClass(notification);
        notification.cols = Math.floor(Math.random() * (6 - 3) + 3);
        notification.from = this.getFrom(notification);
        notification.text = this.getTextByType(notification);
      }
    },
    /**
     * Get class to choose current position on the screen
     * [left, center or right]
     */
    getClass(notification) {
      this.itemPosition++;
      if (this.itemPosition == 1) {
        return "start";
      }
      if (this.itemPosition == 3) {
        this.itemPosition = 0;
        return "end";
      }
      if (this.itemPosition == 2) {
        return "center";
      }
    },
    redirectToTripDate(id) {
      this.$router.push({
        name: "message",
        params: { id: id },
      });
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor">
    <div class="content-body">
      <div class="day-row p-0 m-0">
        <b-row
          v-for="notification in notifications"
          :key="notification.id"
          @click="redirectToTripDate(notification.id)"
          :align-h="notification.class"
          :class="{
            read: notification.is_read,
            unread: !notification.is_read,
          }"
        >
          <b-col :cols="notification.cols" class="p-2">
            <h5 class="font-size-15">
              <a
                href="javascript: void(0);"
                :class="{
                  'text-dark': !notification.is_read,
                  'text-light': notification.is_read,
                }"
                ><i class="bx bx-calendar"></i> Sender: {{ notification.from }}
              </a>
            </h5>
            <p class="mb-0">{{ notification.text }}</p>
          </b-col>
        </b-row>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.calendar-actions > div > .btn {
  font-size: 1.9rem !important;
}
.day-row > .row {
  margin-left: 0;
  margin-right: 0;
}
.day-row > .row > div {
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin-bottom: 10px;
}
.list-group-item {
  background-color: transparent;
  border: 0;
}
.content-body {
  height: 100%;
}
.day-row > .row.read > div {
  background-color: transparent;
  color: white;
}
.day-row > .row.unread > div {
  background-color: yellow;
}
</style>