<script>
import ListHelper from "../mixins/list.helper.mixin";
import SweetAlert from "../mixins/sweetalert.helper.mixin";
import DateFormat from "../mixins/dateformat.helper.mixin";

const dateFields = ["created_at", "updated_at", "departure_datetime"];

/**
 * Table index component
 */
export default {
  components: {},
  mixins: [ListHelper, SweetAlert, DateFormat],
  props: {
    route: {
      type: String,
      default: "",
    },
    moduleName: {
      type: String,
      default: "",
    },
    fields: {
      type: Array,
      default: () => {
        return [];
      },
    },
    fetchDataRequest: {
      type: Function,
    },
    deleteRequest: {
      type: Function,
    },
  },
  data() {
    return {
      showDeleteModal: false,
      deleteId: 0,
      tableData: [],
      tableTitle: this.$t(`module.${this.moduleName}.index`),
      keyword: "",
      totalRows: 0,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
    };
  },
  methods: {
    deleteRole(bvModalEvent) {
      let id = this.deleteId;
      this.deleteRequest(id).then(this.processDeleteResponse);
    },
    processDeleteResponse(response) {
      let id = this.deleteId;
      let i = this.tableData.map((data) => data.id).indexOf(id);
      this.tableData.splice(i, 1);
      this.fireSuccess(this.$t("message.deleted-successfull"));
    },
    processResponse(response) {
      if (!response.data) {
        return;
      }
      let arr = response.data.map((list) => {
        this.formatDateFields(list);
        return this.filterList(this.fields, list);
      });
      if (arr) {
        this.totalRows = response.total;
        this.tableData = arr;
      }
    },
    formatDateFields(list) {
      for (let key in list) {
        if (dateFields.includes(key)) {
          list[key] = this.formatDate(list[key]);
        }
      }
    },
    fetchData() {
      this.tableData = [];
      this.fetchDataRequest({
        page: this.currentPage,
        perPage: this.perPage,
        search: this.keyword,
      }).then(this.processResponse);
    },

    openDeleteModal(item, index, target) {
      this.showDeleteModal = true;
      this.deleteId = item.id;
    },
    onSearch() {
      this.fetchData();
    },
    onFiltered(filteredItems) {},
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    currentPage: {
      handler: function (value) {
        this.fetchData();
      },
    },
    perPage: {
      handler: function (value) {
        this.fetchData();
      },
    },
  },
  computed: {
    rows() {
      return this.tableData.length;
    },
  },
};
</script>

<template>
  <div class="col-12">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">{{ tableTitle }}</h4>
        <div class="row mt-4">
          <div class="col-sm-12 col-md-6">
            <b-modal
              v-model="showDeleteModal"
              title="Modal Heading"
              title-class="font-18"
              @ok="deleteRole"
            >
              <h5>{{ $t("message.delete-confirmation") }}</h5>
            </b-modal>

            <div id="tickets-table_length" class="dataTables_length">
              <router-link
                :to="{ name: route + '.create' }"
                class="btn btn-success"
                ><i class="bx bx-plus-medical"></i> {{ $t('action.add')}}</router-link
              >
              <label class="d-inline-flex align-items-center">
                {{ $t("table.show") }}&nbsp;
                <b-form-select
                  v-model="perPage"
                  size="sm"
                  class="form-select form-select-sm"
                  :options="pageOptions"
                ></b-form-select
                >&nbsp;{{ $t("table.entries") }}
              </label>
            </div>
          </div>
          <!-- Search -->
          <div class="col-sm-12 col-md-6">
            <div
              id="tickets-table_filter"
              class="dataTables_filter text-md-end"
            >
              <label class="d-inline-flex align-items-center">
                {{ $t("action.search") }}:
                <b-form-input
                  v-model="keyword"
                  @change="onSearch"
                  type="search"
                  :placeholder="$t('action.search') + '...'"
                  class="form-control form-control-sm ms-2"
                ></b-form-input>
              </label>
            </div>
          </div>
          <!-- End search -->
        </div>
        <!-- Table -->
        <div class="table-responsive mb-0">
          <b-table
            class="datatables"
            :items="tableData"
            :fields="fields"
            responsive="sm"
            :current-page="currentPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :filter="filter"
            @filtered="onFiltered"
            empty-text="empty text"
          >
            <template #cell(actions)="row">
              <router-link
                :to="{ name: route + '.edit', params: { id: row.item.id } }"
                class="btn btn-primary"
                ><i class="bx bx-edit"></i
              ></router-link>
              <router-link
                :to="{ name: route + '.show', params: { id: row.item.id } }"
                class="btn btn-success"
                ><i class="bx bx-detail"></i
              ></router-link>
              <button
                class="btn btn-danger"
                @click="openDeleteModal(row.item, row.index, $event.target)"
                :alt="$t('action.delete')"
              >
                <i class="bx bx-trash-alt"></i>
              </button>
            </template>
          </b-table>
        </div>
        <div class="row">
          <div class="col">
            <div class="dataTables_paginate paging_simple_numbers float-end">
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
