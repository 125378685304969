import http from '../services/request'

const API_ENDPOINT = "group";

/**
 *@ Get the GroupUser
 */
export const getGroupUsers = id => http.get(API_ENDPOINT+"/"+id+"/user")

/**
 *@ Create a new GroupUser
 */
export const addUserToGroup = (id, params) => http.post(API_ENDPOINT+"/"+id+"/user", params)

/**
 *@ Delete the GroupUser
 */
 export const deleteUserFromGroup = (groupId, userId) => http.delete(API_ENDPOINT+"/"+groupId+"/user/"+userId, {})
