export default {
    data() {
    },
    methods: {
        /**
         * Filter list elements by the required fields
         * 
         * @param {array} fields 
         * @param {array} list 
         * 
         * @returns Filtered array
         */
        filterList(fields, list) {
            let t = {};
            let field;
            t["id"] = list["id"];
            for (let key in fields) {
                field = fields[key];
                if (list.hasOwnProperty(field.key)) {
                    t[field.key] = list[field.key];
                }
            }

            return t;
        },
        /**
         * Make the id,name list from constants
         * (for displaing to the dropdown box)
         * 
         * @param {Freezed object} constant 
         * 
         * @returns 
         */
        constToArray(constant) {
            let data = [];
            for (let key in constant) {
                data.push({
                    id: key,
                    name: key
                });
            }

            return data;
        }
    }
};
