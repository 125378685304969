import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        token: "",
        user: null
    },
    mutations: {
        change(state, response) {
            localStorage.setItem('token', response.access_token);
            state.token = response.access_token;
        },
        removeData(state) {
            state.token = "";
            state.user = null;
            localStorage.removeItem('token');
        },
        addUserData(state, me) {
            state.user = me;
            state.user.name_label = me.name.split(' ')[0];
        },
        initialiseStore(state) {    
            if (localStorage.getItem('token')) {
                state.token = localStorage.getItem('token');
            }
        }
    },
    getters: {
        user: state => state.user,
        token: state => state.token
    }
})