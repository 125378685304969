<script>
import { getGroup, updateGroup, createGroup } from '../../../services/group.api';
import { getUsersForSelectHtmlTag } from "../../../services/user.api";
import { getDeparturesForSelectHtmlTag } from "../../../services/departure.api";
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "group";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "name", type: "input" },
        { name: "departure_id", type: "select", values: [] },
        { name: "user_id", type: "select", values: [] },
        { name: "status", type: "input" },
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUsersForSelectHtmlTag();
    this.getDeparturesForSelectHtmlTag();
  },
  methods: {
    // Get action
    async getUsersForSelectHtmlTag() {
      let resp = await getUsersForSelectHtmlTag().catch((res) => {});
      this.fields[2].values = resp.data;
    },
    // Get action
    async getDeparturesForSelectHtmlTag() {
      let resp = await getDeparturesForSelectHtmlTag().catch((res) => {});
      this.fields[1].values = resp.data;
    },
    getRequest() {
      return getGroup(this.$route.params.id);
    },
    updateRequest(model) {
      return updateGroup(model);
    },
    createRequest(model) {
      return createGroup(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>