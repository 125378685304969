export const menuItems = [

    {
        id: 17,
        label: "module.destination.text",
        icon: "bx-calendar",
        link: "/organize/destination",
        role: "Organizer"
    },
    {
        id: 18,
        label: "module.arrangement.text",
        icon: "bx-calendar",
        link: "/organize/arrangement",
        role: "Organizer"
    },
    // Traveler
    {
        id: 15,
        label: "menuitems.plan-trip",
        icon: "bx-calendar",
        link: "/plan-trip",
        role: "Traveler"
    },
    {
        id: 16,
        label: "menuitems.my-trips",
        icon: "bx-calendar",
        link: "/trips",
        role: "Traveler"
    },
    {
        id: 1,
        label: 'menuitems.users',
        icon: 'bx-home-circle',
        role: "Administrator",
        subItems: [
            {
                id: 0,
                label: "module.role.text",
                icon: "bx-calendar",
                link: "/admin/role",
                role: "Administrator"
            },
            {
                id: 1,
                label: "module.user.text",
                icon: "bx-user",
                link: "/admin/user",
                role: "Administrator"
            },
            {
                id: 14,
                label: "module.level.text",
                icon: "bx-calendar",
                link: "/admin/level",
                role: "Administrator"
            },
        ]
    },

    {
        id: 15,
        label: 'menuitems.trips',
        icon: 'bx-home-circle',
        role: "Administrator",
        subItems: [
            {
                id: 2,
                label: "module.destination.text",
                icon: "bx-calendar",
                link: "/admin/destination",
                role: "Administrator"
            },
            {
                id: 17,
                label: "module.arrangement.text",
                icon: "bx-calendar",
                link: "/admin/arrangement",
                role: "Administrator"
            },
            {
                id: 3,
                label: "module.departure.text",
                icon: "bx-calendar",
                link: "/admin/departure",
                role: "Administrator"
            },
            {
                id: 6,
                label: "module.group.text",
                icon: "bx-calendar",
                link: "/admin/group",
                role: "Administrator"
            },

            {
                id: 8,
                label: "module.cancellationType.text",
                icon: "bx-calendar",
                link: "/admin/cancellationType",
                role: "Administrator"
            },

            {
                id: 9,
                label: "module.cancellation.text",
                icon: "bx-calendar",
                link: "/admin/cancellation",
                role: "Administrator"
            },
            {
                id: 12,
                label: "module.agency.text",
                icon: "bx-calendar",
                link: "/admin/agency",
                role: "Administrator"
            },
            {
                id: 13,
                label: "module.agencyOrganizer.text",
                icon: "bx-calendar",
                link: "/admin/agencyOrganizer",
                role: "Administrator"
            },
        ]
    },



    {
        id: 4,
        label: "module.notificationType.text",
        icon: "bx-calendar",
        link: "/admin/notificationType",
        role: "Administrator"
    },

    {
        id: 5,
        label: "module.notification.text",
        icon: "bx-calendar",
        link: "/admin/notification",
        role: "Administrator"
    },



    {
        id: 10,
        label: "module.login.text",
        icon: "bx-calendar",
        link: "/login",
        role: "Guest"
    },
    {
        id: 11,
        label: "module.register.text",
        icon: "bx-calendar",
        link: "/register",
        role: "Guest"
    }
]