<script>
import { getUser, updateUser, createUser } from "../../../services/user.api";
import { getRoleForSelectHtmlTag } from "../../../services/role.api";
import { getLevelsForSelectHtmlTag } from "../../../services/level.api";
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "user";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "name", type: "input", disabled: false },
        { name: "email", type: "input", disabled: false },
        { name: "password", type: "input", disabled: false },
        { name: "email_verified_at", type: "input", disabled: true },
        { name: "role_id", type: "select", values: [], disabled: false },
        { name: "level_id", type: "select", values: [], disabled: false },
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
          this.getRoleForSelectHtmlTag();
      this.getLevelsForSelectHtmlTag();
  },
  methods: {
    // Get action
    async getLevelsForSelectHtmlTag() {
      let resp = await getLevelsForSelectHtmlTag().catch((res) => {});
      this.fields[5].values = resp.data;
    },

    // Get action
    async getRoleForSelectHtmlTag() {
      let resp = await getRoleForSelectHtmlTag().catch((res) => {});
      this.fields[4].values = resp.data;
    },
    getRequest() {
      return getUser(this.$route.params.id);
    },
    updateRequest(model) {
      return updateUser(this.$route.params.id, model);
    },
    createRequest(model) {
      return createUser(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>