<script>
import { verify } from "../services/auth.api";
import Alert from "../mixins/alerts.mixin";
import SweetAlert from "../mixins/sweetalert.helper.mixin";

export default {
  mixins: [Alert, SweetAlert],
  data() {
    return {
      isVerifyDone: false,
      isVerifyError: false,
    };
  },
  components: {},
  created() {
  },
  beforeCreate: function () {
    document.body.className = "";
    document.getElementsByTagName("html")[0].className = "";
  },
  methods: {
    async verifyUser() {
      let res;
      // Login action
      res = await verify(this.model).catch((res) => {
        this.isVerifyError = true;
      });
      if (res) {
        this.isVerifyDone = true;
        setTimeout(() => {
          this.$router.push({ name: "home" });
        }, 4000);
      }
    },
  },
  mounted() {
    this.verifyUser();
  },
};
</script>

<template>
  <div>
    <div class="account-pages my-5 pt-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-8 col-lg-6 col-xl-5">
            <div class="card overflow-hidden">
              <div class="bg-soft bg-primary">
                <div class="row">
                  <div class="col-7">
                    <div class="text-primary p-4">
                      <h5 class="text-primary">Welcome Back !</h5>
                    </div>
                  </div>
                  <div class="col-5 align-self-end">
                    <img src="/images/profile-img.png" alt class="img-fluid" />
                  </div>
                </div>
              </div>
              <div class="card-body pt-0">
                <div>
                  <a href="/">
                    <div class="avatar-md profile-user-wid mb-4">
                      <span class="avatar-title rounded-circle bg-light">
                        <img
                          src="/images/logo.svg"
                          alt
                          class="rounded-circle"
                          height="34"
                        />
                      </span>
                    </div>
                  </a>
                </div>
                <div class="p-2">
                  <b-alert
                    v-model="isVerifyError"
                    variant="danger"
                    class="mt-3"
                    >{{ $t("message.error.email-verify") }}</b-alert
                  >
                  <b-alert
                    variant="success"
                    class="mt-3"
                    v-model="isVerifyDone"
                    >{{ $t("message.email-verified-successfull") }}</b-alert
                  >
                  <div class="mt-3 d-grid">
                    <router-link
                      :to="{ name: 'home' }"
                      class="btn btn-primary"
                      >{{ $t("action.back-to-dashboard") }}</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
::v-deep .carousel-caption {
  position: relative !important;
  right: 0;
  bottom: 1.25rem;
  left: 0;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #495057;
  text-align: center;
}

::v-deep .carousel-indicators li {
  background-color: rgba(85, 110, 230, 0.25);
}
</style>