import http from '../services/request'

const API_ENDPOINT = "auth";

/**
 *@ Login the User
 */
export const login = params => http.post(API_ENDPOINT+"/login", params)

/**
 *@ Register a new user
 */
 export const register = params => http.post(API_ENDPOINT+"/register", params)

 /**
 *@ Refresh existing token
 */
 export const refresh = () => http.post(API_ENDPOINT+"/refresh")

/**
 *@ Refresh existing token
 */
 export const me = () => http.get(API_ENDPOINT+"/me")

/**
 *@ Mark the authenticated user's email address as verified.
 */
 export const verify = (id, hash) => http.get(API_ENDPOINT+`/email/verify/${id}/${hash}`)

 /**
 *@ Send an password reset link request.
 */
 export const sendResetPasswordEmail = params => http.post(API_ENDPOINT+`/send-email/reset-password`, params)

  /**
 *@ Mark the authenticated user's email address as verified.
 */
 export const resetPassword = params => http.post(API_ENDPOINT+`/reset-password`, params)