import http from '../services/request'

const API_ENDPOINT = "userAgencyFavorite";

/**
 *@ Get paginated list of the UserAgencyFavorite
 */
export const getPaginatedUserAgencyFavorite = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the UserAgencyFavorite
 */
export const getUserAgencyFavorite = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the UserAgencyFavorite
 */
export const updateUserAgencyFavorite= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new UserAgencyFavorite
 */
export const createUserAgencyFavorite = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the UserAgencyFavorite
 */
 export const deleteUserAgencyFavorite = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the UserAgencyFavorite
 */
export const getUserAgencyFavoriteForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")