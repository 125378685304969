import http from '../services/request'

const API_ENDPOINT = "notification-type";

/**
 *@ Get paginated list of the NotificationType
 */
export const getPaginatedNotificationType = params => http.get(API_ENDPOINT, params)

/**
 *@ Get the NotificationType
 */
export const getNotificationType = id => http.get(API_ENDPOINT+"/"+id)

/**
 *@ Update the NotificationType
 */
export const updateNotificationType= params => http.put(API_ENDPOINT+"/"+params.id, params)

/**
 *@ Create a new NotificationType
 */
export const createNotificationType = params => http.post(API_ENDPOINT, params)

/**
 *@ Delete the NotificationType
 */
 export const deleteNotificationType = id => http.delete(API_ENDPOINT+"/"+id, {})

 /**
 *@ Get the NotificationType
 */
export const getNotificationTypesForSelectHtmlTag = () => http.get(API_ENDPOINT+"/selectOptions")