<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import NotificationHelper from "../../mixins/notification.mixin";
import {
  getNotification,
  updateNotification,
} from "../../services/notification.api";
import SweetAlert from "../../mixins/sweetalert.helper.mixin";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  mixins: [SweetAlert, NotificationHelper],
  data() {
    return {
      upcomingTasks: [],
      notification_id: "",
      notification: [],
      showPlaceHolder: true,
      showNoRecordsInfo: false,
      bodyColor: "green",
      headerFooter: {
        actions: this.$getConfig("TRAVELER_LAYOUT_BUTTONS").concat([
          { type: "route", text: "back", route: "notes" },
        ]),
      },
    };
  },
  created() {},
  mounted() {
    this.notification_id = this.$route.params.id;
    this.fetchData();
  },
  methods: {
    async fetchData() {
      this.tableData = [];
      this.showPlaceHolder = true;
      let res = await getNotification(this.notification_id, "me").catch(
        (res) => {
          this.showNoRecordsInfo = true;
        }
      );
      this.processResponse(res);
    },
    processResponse(response) {
      this.showPlaceHolder = false;
      if (!response) {
        return;
      }

      this.notification = response;
      this.notification.text = this.getTextByType(this.notification);
      this.notification.from = this.getFrom(this.notification);

      if (!this.notification.is_read) {
        this.$refs.layout.getNotifications();
      }
    },
    async archive() {
      let response = await updateNotification({
        id: this.notification_id,
        is_archived: true,
      }).catch((res) => {});
      this.processResponse(response);
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor" ref="layout">
    <div
      class="content-body"
      :class="{
        read: notification.is_read,
        unread: !notification.is_read,
      }"
    >
      <div v-if="showNoRecordsInfo" class="card-body">
        <span class="drag-column-header">
          <h4 class="card-title mb-0">There is no notification!</h4>
        </span>
      </div>
      <div v-if="!showNoRecordsInfo" class="card-body">
        <span class="drag-column-header">
          <h4 v-if="!showPlaceHolder" class="card-title mb-4 pb-1">
            Sender: {{ notification.from }}
          </h4>
          <p v-if="!showPlaceHolder">{{ notification.text }}</p>
          <p v-if="showPlaceHolder">
            <span class="placeholder col-3"></span>
          </p>
          <p v-if="showPlaceHolder">
            <span class="placeholder col-4"></span>
          </p>
        </span>
        <div class="text-center d-grid">
          <a
            href="javascript: void(0);"
            class="btn btn-primary btn-block mt-3 btn-archive"
            @click="archive"
            v-if="!notification.is_archived"
          >
            {{ $t("action.archive") }}
          </a>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.content-body {
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin: 10px;
  padding: 6px;
}
.content-body.read,
.content-body.read .card-title {
  background: transparent;
  color: white;
}
.content-body.unread {
  background-color: yellow;
}
.btn-archive {
  font-size: 1.9rem;
}
</style>