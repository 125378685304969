import Home from './components/page/home.vue';
// Administrator
import RoleIndex from './views/admin/role/index.vue';
import RoleForm from './views/admin/role/form.vue';
import UserIndex from './views/admin/user/index.vue';
import UserForm from './views/admin/user/form.vue';
import DestinationIndex from './views/admin/destination/index.vue';
import DestinationForm from './views/admin/destination/form.vue';
import DepartureIndex from './views/admin/departure/index.vue';
import DepartureForm from './views/admin/departure/form.vue';
import NotificationTypeIndex from './views/admin/notificationType/index.vue';
import NotificationTypeForm from './views/admin/notificationType/form.vue';
import NotificationIndex from './views/admin/notification/index.vue';
import NotificationForm from './views/admin/notification/form.vue';
import GroupIndex from './views/admin/group/index.vue';
import GroupForm from './views/admin/group/form.vue';
import CancellationTypeIndex from './views/admin/cancellationType/index.vue';
import CancellationTypeForm from './views/admin/cancellationType/form.vue';
import CancellationIndex from './views/admin/cancellation/index.vue';
import CancellationForm from './views/admin/cancellation/form.vue';
import LevelIndex from './views/admin/level/index.vue';
import LevelForm from './views/admin/level/form.vue';
import AgencyIndex from './views/admin/agency/index.vue';
import AgencyForm from './views/admin/agency/form.vue';
import AgencyOrganizerIndex from './views/admin/agencyOrganizer/index.vue';
import AgencyOrganizerForm from './views/admin/agencyOrganizer/form.vue';
import UserAgencyFavoriteIndex from './views/admin/userAgencyFavorite/index.vue';
import UserAgencyFavoriteForm from './views/admin/userAgencyFavorite/form.vue';
import ArrangementIndex from './views/admin/arrangement/index.vue';
import ArrangementForm from './views/admin/arrangement/form.vue';
// Organizator
import OrganizeDestinationIndex from './views/organize/destination/index.vue';
import OrganizeDestinationForm from './views/organize/destination/form.vue';
import OrganizeArrangementIndex from './views/organize/arrangement/index.vue';
import OrganizeArrangementForm from './views/organize/arrangement/form.vue';
// Traveler
import PlanTrip from './components/page/plan-trip.vue';
import TripDate from './components/page/trip-date.vue';
import Trips from './components/page/trips.vue';
import Notes from './components/page/notes.vue';
import Message from './components/page/message.vue';
import Profile from './components/page/profile.vue';

import PageNotFound from './views/404.vue';
import Login from './views/login.vue';
import Register from './views/register.vue';
import Verify from './views/verify.vue';
import ResetPassword from './views/resetPassword.vue';
import Logout from './views/logout.vue';
import AdminLayout from "./layouts/admin-main";
import Layout from "./layouts/main";
import { store } from './store/store'
import { me } from './services/auth.api';
/*
    This will cehck to see if the user is authenticated or not.
*/
function requireAuth(to, from, next) {

    function checkRole() {
        if ("home" === to.name && "Traveler" === store.getters.user.role) {
            next('trips');
            return;
        }
        if (to.matched[0].meta.permission && to.matched[0].meta.permission !== store.getters.user.role) {
            next({ name: 'home' });
        } else {
            next();
        }
    }

    async function fetchData() {
        let res = await me();
        if (res) {
            store.commit('addUserData', res.data);
            checkRole();
        }
    }

    if (!store.getters.user && !store.getters.token) {
        if ("home" === to.name) {
            next();
        } else {
            next('login');
        }
    } else if (!store.getters.user && store.getters.token) {
        fetchData();
    } else {
        checkRole();
    }
}

export const routes = [
    {
        name: 'home',
        path: '/',
        component: Home,
        beforeEnter: requireAuth
    },
    {
        name: 'plan.trip',
        path: '/plan-trip',
        component: PlanTrip,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'trip.date',
        path: '/trip-date/:date',
        component: TripDate,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'trips',
        path: '/trips',
        component: Trips,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'notes',
        path: '/notes',
        component: Notes,
        beforeEnter: requireAuth,
        props: { isArchive: false },
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'archive',
        path: '/archive',
        component: Notes,
        beforeEnter: requireAuth,
        props: { isArchive: true },
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'message',
        path: '/message/:id',
        component: Message,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Traveler'
        },
    },
    {
        name: 'profile',
        path: '/profile',
        component: Profile,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Traveler'
        },
    },
    // Organizer
    {
        name: 'organize',
        path: '/organize',
        component: AdminLayout,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Organizer'
        },
        children: [
            // Destination
            {
                name: 'organize.destination.index',
                path: 'destination',
                component: OrganizeDestinationIndex
            },
            {
                name: 'organize.destination.create',
                path: 'destination/create',
                component: OrganizeDestinationForm,
                props: { isCreateForm: true }
            },
            {
                name: 'organize.destination.edit',
                path: 'destination/edit/:id',
                component: OrganizeDestinationForm,
                props: { isEditForm: true }
            },
            {
                name: 'organize.destination.show',
                path: 'destination/show/:id',
                component: OrganizeDestinationForm,
                props: { isShowForm: true }
            },
            // Arrangement
            {
                name: 'organize.arrangement.index',
                path: 'arrangement',
                component: OrganizeArrangementIndex
            },
            {
                name: 'organize.arrangement.create',
                path: 'arrangement/create',
                component: OrganizeArrangementForm,
                props: { isCreateForm: true }
            },
            {
                name: 'organize.arrangement.edit',
                path: 'arrangement/edit/:id',
                component: OrganizeArrangementForm,
                props: { isEditForm: true }
            },
            {
                name: 'organize.arrangement.show',
                path: 'arrangement/show/:id',
                component: OrganizeArrangementForm,
                props: { isShowForm: true }
            },
        ]
    },
    {
        name: 'admin',
        path: '/admin',
        component: AdminLayout,
        beforeEnter: requireAuth,
        meta: {
            permission: 'Administrator'
        },
        children: [
            // Role
            {
                name: 'admin.role.index',
                path: 'role',
                component: RoleIndex
            },
            {
                name: 'admin.role.create',
                path: 'role/create',
                component: RoleForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.role.edit',
                path: 'role/edit/:id',
                component: RoleForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.role.show',
                path: 'role/show/:id',
                component: RoleForm,
                props: { isShowForm: true }
            },
            // User
            {
                name: 'admin.user.index',
                path: 'user',
                component: UserIndex
            },
            {
                name: 'admin.user.create',
                path: 'user/create',
                component: UserForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.user.edit',
                path: 'user/edit/:id',
                component: UserForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.user.show',
                path: 'user/show/:id',
                component: UserForm,
                props: { isShowForm: true }
            },
            // Destination
            {
                name: 'admin.destination.index',
                path: 'destination',
                component: DestinationIndex
            },
            {
                name: 'admin.destination.create',
                path: 'destination/create',
                component: DestinationForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.destination.edit',
                path: 'destination/edit/:id',
                component: DestinationForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.destination.show',
                path: 'destination/show/:id',
                component: DestinationForm,
                props: { isShowForm: true }
            },
            // Departure
            {
                name: 'admin.departure.index',
                path: 'departure',
                component: DepartureIndex
            },
            {
                name: 'admin.departure.create',
                path: 'departure/create',
                component: DepartureForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.departure.edit',
                path: 'departure/edit/:id',
                component: DepartureForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.departure.show',
                path: 'departure/show/:id',
                component: DepartureForm,
                props: { isShowForm: true }
            },
            // NotificationType
            {
                name: 'admin.notificationType.index',
                path: 'notificationType',
                component: NotificationTypeIndex
            },
            {
                name: 'admin.notificationType.create',
                path: 'notificationType/create',
                component: NotificationTypeForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.notificationType.edit',
                path: 'notificationType/edit/:id',
                component: NotificationTypeForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.notificationType.show',
                path: 'notificationType/show/:id',
                component: NotificationTypeForm,
                props: { isShowForm: true }
            },
            // Notification
            {
                name: 'admin.notification.index',
                path: 'notification',
                component: NotificationIndex
            },
            {
                name: 'admin.notification.create',
                path: 'notification/create',
                component: NotificationForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.notification.edit',
                path: 'notification/edit/:id',
                component: NotificationForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.notification.show',
                path: 'notification/show/:id',
                component: NotificationForm,
                props: { isShowForm: true }
            },
            // Group
            {
                name: 'admin.group.index',
                path: 'group',
                component: GroupIndex
            },
            {
                name: 'admin.group.create',
                path: 'group/create',
                component: GroupForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.group.edit',
                path: 'group/edit/:id',
                component: GroupForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.group.show',
                path: 'group/show/:id',
                component: GroupForm,
                props: { isShowForm: true }
            },
            // CancellationType
            {
                name: 'admin.cancellationType.index',
                path: 'cancellationType',
                component: CancellationTypeIndex
            },
            {
                name: 'admin.cancellationType.create',
                path: 'cancellationType/create',
                component: CancellationTypeForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.cancellationType.edit',
                path: 'cancellationType/edit/:id',
                component: CancellationTypeForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.cancellationType.show',
                path: 'cancellationType/show/:id',
                component: CancellationTypeForm,
                props: { isShowForm: true }
            },
            // Cancellation
            {
                name: 'admin.cancellation.index',
                path: 'cancellation',
                component: CancellationIndex
            },
            {
                name: 'admin.cancellation.create',
                path: 'cancellation/create',
                component: CancellationForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.cancellation.edit',
                path: 'cancellation/edit/:id',
                component: CancellationForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.cancellation.show',
                path: 'cancellation/show/:id',
                component: CancellationForm,
                props: { isShowForm: true }
            },
            // Level
            {
                name: 'admin.level.index',
                path: 'level',
                component: LevelIndex
            },
            {
                name: 'admin.level.create',
                path: 'level/create',
                component: LevelForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.level.edit',
                path: 'level/edit/:id',
                component: LevelForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.level.show',
                path: 'level/show/:id',
                component: LevelForm,
                props: { isShowForm: true }
            },
            // Agency
            {
                name: 'admin.agency.index',
                path: 'agency',
                component: AgencyIndex
            },
            {
                name: 'admin.agency.create',
                path: 'agency/create',
                component: AgencyForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.agency.edit',
                path: 'agency/edit/:id',
                component: AgencyForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.agency.show',
                path: 'agency/show/:id',
                component: AgencyForm,
                props: { isShowForm: true }
            },
            // AgencyOrganizer
            {
                name: 'admin.agencyOrganizer.index',
                path: 'agencyOrganizer',
                component: AgencyOrganizerIndex
            },
            {
                name: 'admin.agencyOrganizer.create',
                path: 'agencyOrganizer/create',
                component: AgencyOrganizerForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.agencyOrganizer.edit',
                path: 'agencyOrganizer/edit/:id',
                component: AgencyOrganizerForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.agencyOrganizer.show',
                path: 'agencyOrganizer/show/:id',
                component: AgencyOrganizerForm,
                props: { isShowForm: true }
            },
            // UserAgencyFavorite
            {
                name: 'admin.userAgencyFavorite.index',
                path: 'userAgencyFavorite',
                component: UserAgencyFavoriteIndex
            },
            {
                name: 'admin.userAgencyFavorite.create',
                path: 'userAgencyFavorite/create',
                component: UserAgencyFavoriteForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.userAgencyFavorite.edit',
                path: 'userAgencyFavorite/edit/:id',
                component: UserAgencyFavoriteForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.userAgencyFavorite.show',
                path: 'userAgencyFavorite/show/:id',
                component: UserAgencyFavoriteForm,
                props: { isShowForm: true }
            },
            // Arrangement
            {
                name: 'admin.arrangement.index',
                path: 'arrangement',
                component: ArrangementIndex
            },
            {
                name: 'admin.arrangement.create',
                path: 'arrangement/create',
                component: ArrangementForm,
                props: { isCreateForm: true }
            },
            {
                name: 'admin.arrangement.edit',
                path: 'arrangement/edit/:id',
                component: ArrangementForm,
                props: { isEditForm: true }
            },
            {
                name: 'admin.arrangement.show',
                path: 'arrangement/show/:id',
                component: ArrangementForm,
                props: { isShowForm: true }
            },
        ]
    },
    { path: "/login", name: 'login', component: Login },
    { path: "/register", name: 'register', component: Register },
    { path: "/verify-email/:id/:hash", name: 'verify', component: Verify },
    { path: "/reset-password/:token", name: 'resetPassword', component: ResetPassword },
    { path: "/logout", name: 'logout', component: Logout },
    { path: "/help", name: 'help', component: PageNotFound },
    { path: "*", component: PageNotFound }
];
