<script>
import { getAgency, updateAgency, createAgency } from '../../../services/agency.api';
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "agency";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "name", type: "input" }
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
  },
  methods: {
    getRequest() {
      return getAgency(this.$route.params.id);
    },
    updateRequest(model) {
      return updateAgency(model);
    },
    createRequest(model) {
      return createAgency(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>