<script>
import Layout from "../../layouts/frontend";
import PageHeader from "../../components/page-header";
import { getUser, updateUser } from "../../services/user.api";
import SweetAlert from "../../mixins/sweetalert.helper.mixin";

/**
 * Starter component
 */
export default {
  components: { Layout, PageHeader },
  mixins: [SweetAlert],
  props: ["isArchive"],
  data() {
    return {
      errors: {},
      disabled: false,
      model: {
        email: "",
        name: "",
        password: "",
      },
      bodyColor: "red",
      headerFooter: {
        actions: this.$getConfig("TRAVELER_LAYOUT_BUTTONS").concat([
          { type: "route", text: "back", route: "trips" },
        ]),
      },
    };
  },
  mounted() {},
  created() {
    this.fetchData();
  },
  methods: {
    /**
     * Fetch notification data with pagination
     */
    async fetchData() {
      let res = await getUser("me");
      this.processResponse(res);
    },
    async updateProfile() {
      this.disabled = true;

      if (
        this.model.password &&
        this.model.password !== this.model.confirm_password
      ) {
        this.showErrorAlert("Connfirm password error");
        return;
      }
      let data = {
        name: this.model.name,
        password: this.model.password,
        receive_notifications_on_email:
          this.model.receive_notifications_on_email,
      };
      let res = await updateUser("me", data).catch(this.processError);
      if (res) {
        this.fireSuccess(this.$t("message.updated-successfull"));
      }
      this.processResponse(res);
    },
    handleErrors(errors) {
      let msgs = [];
      for (let field in errors) {
        let m = this.transalteErrorMessage(errors[field][0]);
        msgs.push(m);
        this.errors[field] = m;
      }

      return msgs;
    },
    processError(res) {
      this.disabled = false;
      if ("The given data was invalid." === res.data.error) {
        this.errors = res.data.errors;
        this.showErrorAlert(this.handleErrors(res.data.errors));
      }
    },
    /**
     * Process Response
     */
    processResponse(response) {
      this.disabled = false;
      if (!response) {
        return;
      }

      this.model = response;
    },
    transalteErrorMessage(error) {
      switch (error) {
        case "The name field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.name"),
          });
        case "The password field is required.":
          return this.$t("message.error.field-is-required", {
            field: this.$t("db.field.password"),
          });
        case "The password must be at least 8 characters.":
          return this.$t("message.error.password-characters");
        default:
          return error;
      }
    },
  },
};
</script>
<template>
  <Layout :actions="headerFooter.actions" :bodyColor="bodyColor">
    <div class="container">
        <div class="row justify-content-center">
          <div class="col-md-12 col-lg-6 col-xl-6">
          <b-alert variant="danger" class="mt-3" dismissible></b-alert>
          <b-form class="p-2" @submit.prevent="updateProfile">
            <b-form-group
              id="input-group-1"
              :label="$t('db.field.name')"
              label-for="input-1"
              class="mb-3"
            >
              <b-form-input
                id="input-1"
                :class="{ 'is-invalid': errors['name'] }"
                v-model="model.name"
                :disabled="disabled"
                :placeholder="
                  $t('message.enter-field', {
                    field: $t('db.field.name'),
                  })
                "
              ></b-form-input>
              <div v-if="errors['name']" class="invalid-feedback text-light">
                <span>{{ errors["name"] }}</span>
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="$t('db.field.password')"
              label-for="input-2"
              class="mb-3"
            >
              <b-form-input
                id="password"
                :class="{ 'is-invalid': errors['password'] }"
                v-model="model.password"
                :disabled="disabled"
                type="password"
                :placeholder="
                  $t('message.enter-field', {
                    field: $t('db.field.password'),
                  })
                "
              ></b-form-input>
              <div
                v-if="errors['password']"
                class="invalid-feedback text-light"
              >
                <span>{{ errors["password"] }}</span>
              </div>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              :label="$t('db.field.confirm-password')"
              label-for="input-2"
              class="mb-3"
            >
              <b-form-input
                id="password"
                :class="{ 'is-invalid': errors['confirm-password'] }"
                v-model="model.confirm_password"
                :disabled="disabled"
                type="password"
                :placeholder="
                  $t('message.enter-field', {
                    field: $t('db.field.confirm-password'),
                  })
                "
              ></b-form-input>
              <div
                v-if="errors['confirm-password']"
                class="invalid-feedback text-light"
              >
                <span>{{ errors["confirm-password"] }}</span>
              </div>
            </b-form-group>

            <b-form-group
              id="receive_notifications_on_email"
              :label="$t('db.field.email-notifications')"
              label-for="checkbox-receive_notifications_on_email"
              class="mb-3"
            >
              <b-form-checkbox
                id="checkbox-receive_notifications_on_email"
                v-model="model.receive_notifications_on_email"
                plain
                name="checkbox-1"
              >{{ $t("db.field.email-notifications-checkbox") }}</b-form-checkbox>
              <div
                v-if="errors['receive_notifications_on_email']"
                class="invalid-feedback text-light"
              >
                <span>{{ errors["receive_notifications_on_email"] }}</span>
              </div>
            </b-form-group>

            <div class="mt-3 d-grid">
              <b-button
                variant="primary"
                class="btn-block"
                :disabled="disabled"
                type="submit"
                >{{ $t("action.save") }}</b-button
              >
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.calendar-actions > div > .btn {
  font-size: 1.9rem !important;
}
.day-row > .row {
  margin-left: 0;
  margin-right: 0;
}
.day-row > .row > div {
  border: 1px solid #f6f6f6;
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  border-radius: 0.25rem;
  cursor: pointer;
  border: 0;
  margin-bottom: 10px;
}
.list-group-item {
  background-color: transparent;
  border: 0;
}
.content-body {
  height: 100%;
}
.day-row > .row.read > div {
  background-color: transparent;
  color: white;
}
.day-row > .row.unread > div {
  background-color: yellow;
}
</style>