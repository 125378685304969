<script>
import { getAgencyOrganizer, updateAgencyOrganizer, createAgencyOrganizer } from '../../../services/agencyOrganizer.api';
import { getAgenciesForSelectHtmlTag } from '../../../services/agency.api';
import { getUsersForSelectHtmlTag } from '../../../services/user.api';
import FormPage from "../../../components/form-page.vue";

const MODULE_NAME = "agencyOrganizer";

/**
 * Advanced table component
 */
export default {
  components: { FormPage },
  props: ["isCreateForm", "isEditForm", "isShowForm"],
  data() {
    return {
      id: "",
      route: "admin." + MODULE_NAME,
      moduleName: MODULE_NAME,
      fields: [
        { name: "agency_id", type: "select", values: [] },
        { name: "user_id", type: "select", values: [] }
      ],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getUsersForSelectHtmlTag();
    this.getAgenciesForSelectHtmlTag();
  },
  methods: {
    // Get action
    async getUsersForSelectHtmlTag() {
      let resp = await getUsersForSelectHtmlTag().catch((res) => {});
      this.fields[1].values = resp.data;
    },
    // Get action
    async getAgenciesForSelectHtmlTag() {
      let resp = await getAgenciesForSelectHtmlTag().catch((res) => {});
      this.fields[0].values = resp.data;
    },
    getRequest() {
      return getAgencyOrganizer(this.$route.params.id);
    },
    updateRequest(model) {
      return updateAgencyOrganizer(model);
    },
    createRequest(model) {
      return createAgencyOrganizer(model);
    },
  },
};
</script>

  <template>
  <div class="row">
    <FormPage
      :route="route"
      :moduleName="moduleName"
      :getRequest="getRequest"
      :updateRequest="updateRequest"
      :createRequest="createRequest"
      :isEditForm="isEditForm"
      :isCreateForm="isCreateForm"
      :isShowForm="isShowForm"
      :id="id"
      :fields="fields"
    >
    </FormPage>
  </div>
  <!-- end row -->
</template>